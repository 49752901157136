<template>
  <v-app>
    <div class="login">
      <div class="container">
        <div>{{ this.message }}</div>
        <div style="text-align: left">
          <div class="form-group">
            <label for="form_phone">電話番号</label>
            <input v-model="form.phone" type="tel" class="form-control" id="form_phone" placeholder="電話番号" required />
          </div>
          <div class="form-group">
            <label for="form_age">年齢</label>
            <select v-model="form.age" class="form-control" name="form_age" id="form_age" required>
              <option value="">選択してください</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
              <option value="51">51</option>
              <option value="52">52</option>
              <option value="53">53</option>
              <option value="54">54</option>
              <option value="55">55</option>
              <option value="56">56</option>
              <option value="57">57</option>
              <option value="58">58</option>
              <option value="59">59</option>
              <option value="60">60</option>
              <option value="61">61</option>
              <option value="62">62</option>
              <option value="63">63</option>
              <option value="64">64</option>
              <option value="65">65</option>
              <option value="66">66</option>
              <option value="67">67</option>
              <option value="68">68</option>
              <option value="69">69</option>
              <option value="70">70</option>
              <option value="71">71</option>
              <option value="72">72</option>
              <option value="73">73</option>
              <option value="74">74</option>
              <option value="75">75</option>
              <option value="76">76</option>
              <option value="77">77</option>
              <option value="78">78</option>
              <option value="79">79</option>
              <option value="80">80</option>
              <option value="81">81</option>
              <option value="82">82</option>
              <option value="83">83</option>
              <option value="84">84</option>
              <option value="85">85</option>
            </select>
          </div>
          <label for="user_maleraw_attributes_貴方の活動範囲（会える都道府県）を教えて下さい。(最大10個まで)">貴方の活動範囲(最大10個まで)</label>
          <div class="white-card" v-for="m in regions.length" :key="regions[m]" style="padding-top: 0px; padding-bottom: 0px">
            <v-row style="margin: 0px 1px 0px 1px">
              <v-col cols="3" class="d-flex align-center" style="background: #7553df; border-top-left-radius: 5px; border-bottom-left-radius: 5px">
                <p style="margin: auto; color: white; font-weight: 700">
                  {{ regions_jp[m - 1] }}
                </p>
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-checkbox
                    style="font-size: 10px"
                    cols="3"
                    v-for="n in area_count[regions[m - 1]]"
                    :key="area_list[n - 1 + area_count_dif[regions[m - 1]]]"
                    v-model="form.area"
                    :label="area_list[n - 1 + area_count_dif[regions[m - 1]]]"
                    :value="area_list[n - 1 + area_count_dif[regions[m - 1]]]"
                    :disabled="form.area.length > 9 && !form.area.includes(area_list[n - 1 + area_count_dif[regions[m - 1]]])"
                  ></v-checkbox>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <label>あなたの体型</label>
          <div class="white-card">
            <v-radio-group row v-model="form.body_type" required>
              <v-radio label="痩せ型" value="痩せ型"></v-radio>
              <v-radio label="普通" value="普通"></v-radio>
              <v-radio label="マッチョ" value="マッチョ"></v-radio>
              <v-radio label="ぽっちゃり" value="ぽっちゃり"></v-radio>
              <v-radio label="かなりぽっちゃり" value="かなりぽっちゃり"></v-radio>
            </v-radio-group>
          </div>
          <label>あなたの身長</label>
          <div class="white-card">
            <v-radio-group v-model="form.height" required>
              <v-radio label="160センチ以下" value="160センチ以下"></v-radio>
              <v-radio label="161~165センチ" value="161~165センチ"></v-radio>
              <v-radio label="166~170センチ" value="166~170センチ"></v-radio>
              <v-radio label="171~175センチ" value="171~175センチ"></v-radio>
              <v-radio label="176~180センチ" value="176~180センチ"></v-radio>
              <v-radio label="180センチ以上" value="180センチ以上"></v-radio>
            </v-radio-group>
          </div>

          <label>あなたの体重</label>
          <div class="white-card">
            <v-radio-group row v-model="form.weight" required>
              <v-radio label="50キロ以下" value="50キロ以下"></v-radio>
              <v-radio label="51~55キロ" value="51~55キロ"></v-radio>
              <v-radio label="56~60キロ" value="56~60キロ"></v-radio>
              <v-radio label="61~65キロ" value="61~65キロ"></v-radio>
              <v-radio label="66~70キロ" value="66~70キロ"></v-radio>
              <v-radio label="71~75キロ" value="71~75キロ"></v-radio>
              <v-radio label="76~80キロ" value="76~80キロ"></v-radio>
              <v-radio label="81~99キロ" value="81~99キロ"></v-radio>
              <v-radio label="100キロ以上" value="100キロ以上"></v-radio>
            </v-radio-group>
          </div>

          <label>撮影</label>
          <div class="white-card">
            <v-radio-group row v-model="form.takemovie" required>
              <v-radio label="したいです！" value="したいです！"></v-radio>
              <v-radio label="相手と相談" value="相手と相談"></v-radio>
              <v-radio label="しません！" value="しません！"></v-radio>
            </v-radio-group>
          </div>

          <div v-if="false">
            <label>性格</label>
            <div class="white-card">
              <v-radio-group row v-model="form.personarity" required>
                <v-radio label="明るい" value="明るい"></v-radio>
                <v-radio label="サバサバ" value="サバサバ"></v-radio>
                <v-radio label="優しい" value="優しい"></v-radio>
                <v-radio label="熱い" value="熱い"></v-radio>
                <v-radio label="甘えたがり" value="甘えたがり"></v-radio>
                <v-radio label="しっかり者" value="しっかり者"></v-radio>
                <v-radio label="大人" value="大人"></v-radio>
                <v-radio label="カッコいい系" value="カッコいい系"></v-radio>
                <v-radio label="可愛い系" value="可愛い系"></v-radio>
                <v-radio label="犬系" value="犬系"></v-radio>
                <v-radio label="猫系" value="猫系"></v-radio>
              </v-radio-group>
            </div>
          </div>

          <label>経験人数</label>
          <div class="white-card">
            <v-select
              name="form_sexual_experience"
              v-model="form.sexual_experience"
              v-bind:items="sexual_experience_list"
              item-text="label"
              item-value="value"
              label="経験人数"
              required
            ></v-select>
          </div>

          <label>都合のつきやすいタイミング</label>
          <div class="white-card">
            <v-row style="padding: 10px">
              <v-checkbox
                v-for="n in 4"
                :key="freetimes_list[n - 1]"
                row
                v-model="form.your_freetimes"
                :label="freetimes_list[n - 1]"
                :value="freetimes_list[n - 1]"
              ></v-checkbox>
            </v-row>
          </div>

          <label>あなたの許容範囲</label>
          <div class="white-card">
            <v-radio-group v-model="form.your_pre_dm" label="DM" required>
              <v-radio label="1日にたくさんしたい" value="1日にたくさんしたい"></v-radio>
              <v-radio label="1日に数回はやりとりしたい" value="1日に数回はやりとりしたい"></v-radio>
              <v-radio label="相手に合わせる" value="相手に合わせる"></v-radio>
              <v-radio label="あまり返せない" value="あまり返せない"></v-radio>
            </v-radio-group>
          </div>

          <div class="white-card">
            <v-radio-group v-model="form.your_pre_tel" label="会う前の電話" required>
              <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
              <v-radio label="できればしたい" value="できればしたい"></v-radio>
              <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
              <v-radio label="したくない" value="したくない"></v-radio>
            </v-radio-group>
          </div>

          <div class="white-card">
            <v-radio-group v-model="form.your_pre_photo" label="会う前の写真交換" required>
              <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
              <v-radio label="できればしたい" value="できればしたい"></v-radio>
              <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
              <v-radio label="したくない" value="したくない"></v-radio>
            </v-radio-group>
          </div>

          <div class="white-card">
            <v-radio-group v-model="form.your_pre_contact" label="連絡先交換" required>
              <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
              <v-radio label="できればしたい" value="できればしたい"></v-radio>
              <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
              <v-radio label="したくない" value="したくない"></v-radio>
            </v-radio-group>
          </div>

          <div style="margin-top: 25px; text-align: center">
            <span style="font-size: 0.7rem; color: red">画像の無断利用/無断転載は禁止です。</span>
          </div>
          <div v-for="m in form_columns.length" :key="m">
            <label>{{ form_columns_jp[m - 1] }}</label>
            <div class="white-card">
              <v-container>
                <v-row dense fill-height>
                  <v-col cols="6" sm="6" md="3" lg="3" xl="3" class="pl-5">
                    <div
                      style="width: 100%; max-height: 400px; border-radius: 10px"
                      :style="[
                        {
                          'background-image': 'url(' + require(`@/assets/form/${form_columns[m - 1]}.jpg`) + ')',
                          width: '100%',
                          'max-height': '400px',
                          'border-radius': '10px',
                          'min-height': '168px',
                          'background-size': 'cover',
                        },
                      ]"
                    ></div>
                  </v-col>
                  <v-col cols="6" sm="6" md="9" lg="9" xl="9">
                    <v-container class="px-2 py-0" fill-height>
                      <v-row class="justify-center align-content-space-between" fill-height style="vertical-align: middle; height: 100%">
                        <v-col class="pa-1 align-content-space-between q-box" cols="12" sm="12" md="6" lg="6" xl="6" v-for="n in 4" :key="'a' + n">
                          <v-card
                            flat
                            class="full-height pa-1 d-flex flex-column justify-center q-box-text"
                            color="#D95B89"
                            style="height: 100%; text-align: center; color: white"
                            v-if="form[form_columns[m - 1]] == form_text[form_columns[m - 1]][n - 1]"
                            @click="form.form_columns[m - 1] = form_text[form_columns[m - 1]][n - 1]"
                          >
                            {{ form_text[form_columns[m - 1]][n - 1] }}
                          </v-card>
                          <v-card
                            flat
                            class="full-height pa-1 d-flex flex-column justify-center q-box-text"
                            color="grey lighten-4"
                            style="height: 100%; text-align: center"
                            v-else
                            @click="form[form_columns[m - 1]] = form_text[form_columns[m - 1]][n - 1]"
                          >
                            {{ form_text[form_columns[m - 1]][n - 1] }}
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
          <div v-if="false">
            <div class="white-card">
              <v-radio-group v-model="form.your_blow_job" label="フェラ" required>
                <v-radio label="積極的にやってほしい" value="積極的にやってほしい"></v-radio>
                <v-radio label="やってほしい" value="やってほしい"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やらなくていい" value="やらなくていい"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_cunnilingus" label="クンニ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_choking" label="首しめ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_kousoku" label="拘束" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_kisuma" label="噛み跡" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_nouiki" label="催眠" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_anal_sex" label="アナル" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_word_attack_easy" label="優しい言葉ぜめ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_word_attack_hard" label="激しい言葉ぜめ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_spanking" label="スパンキング" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_wild" label="野外プレイ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_ejaculation" label="潮吹き" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_irrumatio" label="イラマチオ" required>
                <v-radio label="積極的にやりたい" value="積極的にやりたい"></v-radio>
                <v-radio label="やりたい（興味がある）" value="やりたい（興味がある）"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やりたくない（できない）" value="やりたくない（できない）"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_multi" label="複数人" required>
                <v-radio label="絶対にしたい" value="絶対にしたい"></v-radio>
                <v-radio label="できればしたい" value="できればしたい"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="したくない" value="したくない"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_cosplay" label="コスプレ" required>
                <v-radio label="積極的にやってほしい" value="積極的にやってほしい"></v-radio>
                <v-radio label="やってほしい" value="やってほしい"></v-radio>
                <v-radio label="女性が求めるなら！" value="女性が求めるなら！"></v-radio>
                <v-radio label="やらなくていい" value="やらなくていい"></v-radio>
              </v-radio-group>
            </div>

            <div class="white-card">
              <v-radio-group v-model="form.your_sextoy" label="おもちゃ" required>
                <v-radio label="挿入系＆振動系使いたい" value="挿入系＆振動系使いたい"></v-radio>
                <v-radio label="挿入系使いたい" value="挿入系使いたい"></v-radio>
                <v-radio label="振動系使いたい" value="振動系使いたい"></v-radio>
                <v-radio label="おもちゃは使いたくない" value="おもちゃは使いたくない"></v-radio>
              </v-radio-group>
            </div>
          </div>

          <label>貴方のS度（数字が大きい程ドS）</label>

          <div class="white-card">
            <v-radio-group v-model="form.your_sadism" required>
              <v-radio label="１，いちゃいちゃしてるのが一番落ち着く" value="１，いちゃいちゃしてるのが一番落ち着く"></v-radio>
              <v-radio label="２，長めに激しく攻めるのが好き" value="２，長めに激しく攻めるのが好き"></v-radio>
              <v-radio
                label="３，首絞め、スパンキング、おもちゃ攻め、拘束などに興奮をする"
                value="３，首絞め、スパンキング、おもちゃ攻め、拘束などに興奮をする"
              ></v-radio>
              <v-radio label="４，暴力的・支配的行為で興奮を覚える" value="４，暴力的・支配的行為で興奮を覚える"></v-radio>
            </v-radio-group>
          </div>

          <label>貴方のM度（数字が大きい程ドM）</label>
          <div class="white-card">
            <v-radio-group v-model="form.your_masochism" required>
              <v-radio label="１，攻められるのは苦手" value="１，攻められるのは苦手"></v-radio>
              <v-radio label="２，自分が攻めたい" value="２，自分が攻めたい"></v-radio>
              <v-radio label="３，拘束して攻めて欲しい" value="３，拘束して攻めて欲しい"></v-radio>
              <v-radio
                label="４，徹底していじめられたい（射精管理、スパンキング、暴力的性行為）"
                value="４，徹底していじめられたい（射精管理、スパンキング、暴力的性行為）"
              ></v-radio>
            </v-radio-group>
          </div>

          <label>マッチング年齢上限</label>
          <div class="white-card">
            <v-select
              name="form_age_range"
              v-model="form.age_range"
              v-bind:items="age_range_list"
              item-text="label"
              item-value="value"
              required
              return-object
            ></v-select>
          </div>

          <label>アピールポイント 3つまで選択してください。その他に記入する場合は文章ではなく名称のみ</label>
          <div class="white-card">
            <v-row style="padding: 10px">
              <v-checkbox
                v-for="n in 10"
                :key="appear_points_list[n - 1]"
                row
                v-model="form.appear_points"
                :label="appear_points_list[n - 1]"
                :value="appear_points_list[n - 1]"
                :disabled="form.appear_points.length > 2 && !form.appear_points.includes(appear_points_list[n - 1])"
              ></v-checkbox>
            </v-row>
            <transition>
              <v-text-field
                v-model="appear_point_other"
                style="margin: 10px"
                v-show="form.appear_points.includes('その他')"
                label="その他アピールポイント"
              ></v-text-field>
            </transition>
          </div>

          <div v-if="false">
            <label>
              貴方のエッチの特徴、性癖、嗜好(50文字以上)
              <span v-if="form.propensity.replace(/\s+/g, '').length < 50" style="color: red">
                {{ form.propensity.replace(/\s+/g, '').length }}文字 50文字以上入力してください
              </span>
              <span v-else>{{ form.propensity.replace(/\s+/g, '').length }}文字</span>
            </label>
            <div class="white-card">
              <v-textarea v-model="form.propensity" required></v-textarea>
            </div>
          </div>

          <label>相手の体型許容度 ぽっちゃりへの許容が出来る方はレコメンドされやすくなります。</label>
          <div class="white-card">
            <v-radio-group v-model="form.body_type_range" required>
              <v-radio label="ぽっちゃりNG" value="ぽっちゃりNG"></v-radio>
              <v-radio label="ぽっちゃりOK" value="ぽっちゃりOK"></v-radio>
              <v-radio label="ぽっちゃり大好き" value="ぽっちゃり大好き"></v-radio>
            </v-radio-group>
          </div>
          <label>プロフィールを更新する際、以下の項目を毎回チェックしてください</label>
          <label style="color: red; font-size: 1rem">
            <Icon name="exclamation-triangle" scale="1" />
            DMが解放されてない場合マッチンしても女性からを送ることができません。DMの解放をお願いします。
          </label>
          <div class="white-card">
            <v-radio-group row v-model="check.no1" required>
              <v-radio label="確認しました" value="確認しました"></v-radio>
            </v-radio-group>
          </div>

          <label style="color: red; font-size: 1rem">
            <Icon name="exclamation-triangle" scale="1" />
            ユーザー様へ向けた情報を配信しているので、必ず
            <a class="alertlink" href="https://twitter.com/yamada_hona" _target="blank">@yamada_hona</a>
            と
            <a class="alertlink" href="https://twitter.com/yamadahonachan" _target="blank">＠yamadahonachan</a>
            をフォローしてください。
          </label>
          <div class="white-card">
            <v-radio-group row v-model="check.no2" required>
              <v-radio label="確認しました" value="確認しました"></v-radio>
            </v-radio-group>
          </div>
          <button v-if="is_postable" class="btn-comp login-btn" @click="post()">更新</button>
          <button v-if="!is_postable" class="btn-comp login-btn" style="background: gray" disabled>全て入力してください。</button>
        </div>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
  .container {
    max-width: 800px;
  }
  .alertlink {
    font-size: 1rem;
  }
  .q-box {
    height: 25%;
  }
  .q-box-text {
    font-size: 0.6rem;
  }
  @media (min-width: 768px) {
    .q-box {
      height: 50%;
    }
    .q-box-text {
      font-size: 1rem;
    }
  }
  .q-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
  }
  .alertlink {
    font-size: 1rem;
  }
  h2 {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 50px;
  }
  label {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 30px;
  }
  input {
    margin-top: 5px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }
  .v-input {
    margin-bottom: 0;
  }
  a {
    color: #4b4b4b;
  }
  a:hover {
    color: #4b4b4b;
  }
  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }

  .signin-btn {
    background: rgb(97, 34, 219);
    background: linear-gradient(90deg, rgba(97, 34, 219, 1) 0%, rgba(91, 103, 229, 1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text {
    font-size: 0.8rem;
    padding-top: 20px;
  }
  .get-account-information-text {
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  .v-input {
    margin-left: 15px;
  }
  .v-radio {
    margin-bottom: 10px;
  }
  .v-input__control {
    margin-right: 15px;
  }
</style>

<script>
  import axios from '../../util/axios'
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'

  export default {
    name: 'form',
    components: {
      Icon,
    },
    data: function () {
      return {
        uid: '',
        client: '',
        access_token: '',
        id: '',
        message: '',
        data_all: '',
        appear_point_other: '',
        sexual_experience_list: [
          { label: '0人', value: '0人' },
          { label: '1人', value: '1人' },
          { label: '2人', value: '2人' },
          { label: '3人', value: '3人' },
          { label: '4人', value: '4人' },
          { label: '5人', value: '5人' },
          { label: '6人', value: '6人' },
          { label: '7人', value: '7人' },
          { label: '8人', value: '8人' },
          { label: '9人', value: '9人' },
          { label: '10人', value: '10人' },
          { label: '11〜20人', value: '11〜20人' },
          { label: '21〜30人', value: '21〜30人' },
          { label: '31〜50人', value: '31〜50人' },
          { label: '51〜100人', value: '51〜100人' },
          { label: '101人以上', value: '101人以上' },
        ],
        appear_points_list: [
          'いちゃいちゃ',
          'キス',
          '手マン',
          'クンニ',
          'ピストン運動',
          '拘束プレイ',
          '中イキ開発',
          '催眠',
          'アソコの大きさ',
          'その他',
        ],
        age_range_list: ['20代', '30代前半まで', '30代後半まで', '40代前半まで', '40代後半まで', '50代以上もいける'],
        form_columns: [
          'your_blow_job',
          'your_cunnilingus',
          'your_choking',
          'your_kousoku',
          'your_kisuma',
          'your_nouiki',
          'your_anal_sex',
          'your_word_attack_easy',
          'your_word_attack_hard',
          'your_spanking',
          'your_wild',
          'your_ejaculation',
          'your_irrumatio',
          'your_multi',
          'your_cosplay',
          'your_sextoy',
        ],
        form_columns_jp: [
          'フェラ',
          'クンニ',
          '首しめ',
          '拘束',
          '噛み跡',
          '催眠',
          'アナル',
          '優しい言葉責め',
          '激しい言葉責め',
          'スパンキング',
          '野外プレイ',
          '潮吹き',
          'イラマチオ',
          '複数人',
          'コスプレ',
          'おもちゃ',
        ],
        form_text: {
          your_blow_job: ['積極的にやってほしい', 'やってほしい', '女性が求めるなら！', 'やらなくていい'],
          your_cunnilingus: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_choking: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_kousoku: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_kisuma: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_nouiki: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_anal_sex: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_word_attack_easy: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_word_attack_hard: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_spanking: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_wild: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_ejaculation: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_irrumatio: ['積極的にやりたい', 'やりたい（興味がある）', '女性が求めるなら！', 'やりたくない（できない）'],
          your_multi: ['絶対にしたい', 'できればしたい', '女性が求めるなら！', 'したくない'],
          your_cosplay: ['積極的にやってほしい', 'やってほしい', '女性が求めるなら！', 'やらなくていい'],
          your_sextoy: ['挿入系＆振動系使いたい', '挿入系使いたい', '振動系使いたい', 'おもちゃは使いたくない'],
        },
        area_list: [
          '北海道（札幌）',
          '北海道（道東）',
          '北海道（道南）',
          '北海道（道北）',
          '北海道（道央）',
          '青森県',
          '岩手県',
          '秋田県',
          '山形県',
          '宮城県',
          '福島県',
          '栃木県',
          '茨城県',
          '群馬県',
          '千葉県',
          '埼玉県',
          '東京都',
          '神奈川県',
          '山梨県',
          '新潟県',
          '長野県',
          '富山県',
          '石川県',
          '福井県',
          '静岡県',
          '愛知県',
          '岐阜県',
          '三重県',
          '滋賀県',
          '奈良県',
          '京都府',
          '大阪府',
          '和歌山県',
          '兵庫県',
          '岡山県',
          '広島県',
          '鳥取県',
          '島根県',
          '山口県',
          '香川県',
          '徳島県',
          '高知県',
          '愛媛県',
          '福岡県',
          '長崎県',
          '佐賀県',
          '大分県',
          '熊本県',
          '宮崎県',
          '鹿児島県',
          '沖縄県',
        ],
        regions: ['hokkaido', 'tohoku', 'kanto', 'chubu', 'kansai', 'chugoku', 'sikoku', 'kyushu'],
        regions_jp: ['北海道', '東北', '関東', '中部', '関西', '中国', '四国', '九州'],
        area_count: {
          hokkaido: 5,
          tohoku: 6,
          kanto: 7,
          chubu: 9,
          kansai: 7,
          chugoku: 5,
          sikoku: 4,
          kyushu: 8,
        },
        area_count_dif: {
          hokkaido: 0,
          tohoku: 5,
          kanto: 11,
          chubu: 18,
          kansai: 27,
          chugoku: 34,
          sikoku: 39,
          kyushu: 43,
        },
        freetimes_list: ['平日の昼間', '平日の夜', '土日祝日の昼間', '土日祝日の夜'],
        check: {
          no1: null,
          no2: null,
        },
        form: {
          phone: '',
          twitterid: '',
          twitter_name: '',
          age: '',
          area: [],
          body_type: '',
          height: '',
          weight: '',
          personarity: '',
          takemovie: '',
          sexual_experience: '',
          your_pre_dm: '',
          your_pre_tel: '',
          your_pre_photo: '',
          your_pre_contact: '',
          your_blow_job: '',
          your_cunnilingus: '',
          your_choking: '',
          your_kousoku: '',
          your_kisuma: '',
          your_nouiki: '',
          your_anal_sex: '',
          your_word_attack_easy: '',
          your_word_attack_hard: '',
          your_spanking: '',
          your_wild: '',
          your_ejaculation: '',
          your_irrumatio: '',
          your_multi: '',
          your_sadism: '',
          your_masochism: '',
          your_freetimes: [],
          your_sextoy: '',
          your_cosplay: '',
          age_range: [],
          appear_points: [],
          appear_points_standardized: '',
          propensity: '',
          body_type_range: '',
        },
      }
    },
    mounted() {
      this.getAccountData()
      if (this.uid == null) {
        this.$router.push('/login')
      }
    },
    computed: {
      appear_points_result: function () {
        if (this.form.appear_points.includes('その他')) {
          const ar = this.form.appear_points.filter((i) => i != 'その他')
          ar.push(this.appear_point_other)
          return ar.join(', ')
        } else {
          return this.form.appear_points.join(', ')
        }
      },
      is_postable() {
        if (
          this.form.phone != null &&
          this.form.phone != '' &&
          this.form.age != null &&
          this.form.age != '' &&
          this.form.area != null &&
          this.form.area != '' &&
          this.form.body_type != null &&
          this.form.body_type != '' &&
          this.form.height != null &&
          this.form.height != '' &&
          this.form.weight != null &&
          this.form.weight != '' &&
          this.form.takemovie != null &&
          this.form.takemovie != '' &&
          this.form.sexual_experience != null &&
          this.form.sexual_experience != '' &&
          this.form.your_pre_dm != null &&
          this.form.your_pre_dm != '' &&
          this.form.your_pre_tel != null &&
          this.form.your_pre_tel != '' &&
          this.form.your_pre_photo != null &&
          this.form.your_pre_photo != '' &&
          this.form.your_pre_contact != null &&
          this.form.your_pre_contact != '' &&
          this.form.your_blow_job != null &&
          this.form.your_blow_job != '' &&
          this.form.your_cunnilingus != null &&
          this.form.your_cunnilingus != '' &&
          this.form.your_choking != null &&
          this.form.your_choking != '' &&
          this.form.your_kousoku != null &&
          this.form.your_kousoku != '' &&
          this.form.your_kisuma != null &&
          this.form.your_kisuma != '' &&
          this.form.your_nouiki != null &&
          this.form.your_nouiki != '' &&
          this.form.your_anal_sex != null &&
          this.form.your_anal_sex != '' &&
          this.form.your_word_attack_easy != null &&
          this.form.your_word_attack_easy != '' &&
          this.form.your_word_attack_hard != null &&
          this.form.your_word_attack_hard != '' &&
          this.form.your_spanking != null &&
          this.form.your_spanking != '' &&
          this.form.your_wild != null &&
          this.form.your_wild != '' &&
          this.form.your_ejaculation != null &&
          this.form.your_ejaculation != '' &&
          this.form.your_irrumatio != null &&
          this.form.your_irrumatio != '' &&
          this.form.your_multi != null &&
          this.form.your_multi != '' &&
          this.form.your_sadism != null &&
          this.form.your_sadism != '' &&
          this.form.your_masochism != null &&
          this.form.your_masochism != '' &&
          this.form.your_freetimes != null &&
          this.form.your_freetimes != '' &&
          this.form.your_sextoy != null &&
          this.form.your_sextoy != '' &&
          this.form.your_cosplay != null &&
          this.form.your_cosplay != '' &&
          this.form.age_range != null &&
          this.form.age_range != '' &&
          this.form.appear_points != null &&
          this.form.appear_points != '' &&
          this.form.body_type_range != null &&
          this.form.body_type_range != '' &&
          this.check.no1 != null &&
          this.check.no1 != '' &&
          this.check.no2 != null &&
          this.check.no2 != ''
        ) {
          return true
        } else {
          return false
        }
      },
    },
    methods: {
      includes_other() {
        const r_array = this.form.appear_points.filter((i) => this.appear_points_list.indexOf(i) == -1)
        if (r_array.length != 0) {
          this.appear_point_other = r_array[0]
          this.form.appear_points = this.form.appear_points.filter((i) => i != this.appear_point_other)
          this.form.appear_points.push('その他')
        }
      },

      async getAccountData() {
        this.uid = localStorage.getItem('uid')
        this.client = localStorage.getItem('client')
        this.access_token = localStorage.getItem('access-token')
        if (this.uid) {
          const result = await axios
            .get('/api/account-detail', {
              headers: {
                uid: this.uid,
                'access-token': this.access_token,
                client: this.client,
              },
            })
            .catch((e) => {
              console.error(e)
            })

          if (!result) {
            // エラーの場合ログイン画面へ遷移させる
            this.message = 'エラーが発生しました。'
            return
          }

          if (result.data.state) {
            this.id = result.data.id
            this.data_all = result.data
            this.form.phone = result.data.phone
            this.form.twitterid = result.data.twitterid
            this.form.twitter_name = result.data.twitter_name
            this.form.age = result.data.age
            this.form.area = result.data.area
            this.form.body_type = result.data.body_type
            this.form.height = result.data.height
            this.form.weight = result.data.weight
            this.form.personarity = result.data.personarity
            this.form.takemovie = result.data.takemovie
            this.form.sexual_experience = result.data.sexual_experience
            this.form.your_pre_dm = result.data.your_pre_dm
            this.form.your_pre_tel = result.data.your_pre_tel
            this.form.your_pre_photo = result.data.your_pre_photo
            this.form.your_pre_contact = result.data.your_pre_contact
            this.form.your_blow_job = result.data.your_blow_job
            this.form.your_cunnilingus = result.data.your_cunnilingus
            this.form.your_choking = result.data.your_choking
            this.form.your_kousoku = result.data.your_kousoku
            this.form.your_kisuma = result.data.your_kisuma
            this.form.your_nouiki = result.data.your_nouiki
            this.form.your_anal_sex = result.data.your_anal_sex
            this.form.your_word_attack_easy = result.data.your_word_attack_easy
            this.form.your_word_attack_hard = result.data.your_word_attack_hard
            this.form.your_spanking = result.data.your_spanking
            this.form.your_wild = result.data.your_wild
            this.form.your_ejaculation = result.data.your_ejaculation
            this.form.your_irrumatio = result.data.your_irrumatio
            this.form.your_multi = result.data.your_multi
            this.form.your_sadism = result.data.your_sadism
            this.form.your_masochism = result.data.your_masochism
            this.form.your_freetimes = result.data.your_freetimes
            this.form.your_sextoy = result.data.your_sextoy
            this.form.your_cosplay = result.data.your_cosplay

            this.form.age_range = result.data.age_range
            this.form.appear_points = result.data.appear_points
            this.form.appear_points_standardized = result.data.appear_points
            this.form.propensity = result.data.propensity
            this.form.body_type_range = result.data.body_type_range
            if (this.form.propensity == null) {
              this.form.propensity = ''
            }
            if (this.form.appear_points == '') {
              this.form.appear_points = []
            }
            this.includes_other()
          }
        }
      },
      async post() {
        const result = await axios({
          method: 'POST',
          url: '/api/user/update',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            user: {
              id: this.id,
              phone: this.form.phone,
              twitterid: this.form.twitterid,
              maleraw_attributes: {
                twitter_name: this.form.twitter_name,
                age: this.form.age,
                area: this.form.area.join(', '),
                body_type: this.form.body_type,
                height: this.form.height,
                weight: this.form.weight,
                personarity: this.form.personarity,
                takemovie: this.form.takemovie,
                sexual_experience: this.form.sexual_experience,
                your_pre_dm: this.form.your_pre_dm,
                your_pre_tel: this.form.your_pre_tel,
                your_pre_photo: this.form.your_pre_photo,
                your_pre_contact: this.form.your_pre_contact,
                your_blow_job: this.form.your_blow_job,
                your_cunnilingus: this.form.your_cunnilingus,
                your_choking: this.form.your_choking,
                your_kousoku: this.form.your_kousoku,
                your_kisuma: this.form.your_kisuma,
                your_nouiki: this.form.your_nouiki,
                your_anal_sex: this.form.your_anal_sex,
                your_word_attack_easy: this.form.your_word_attack_easy,
                your_word_attack_hard: this.form.your_word_attack_hard,
                your_spanking: this.form.your_spanking,
                your_wild: this.form.your_wild,
                your_ejaculation: this.form.your_ejaculation,
                your_irrumatio: this.form.your_irrumatio,
                your_multi: this.form.your_multi,
                your_sadism: this.form.your_sadism,
                your_masochism: this.form.your_masochism,
                your_freetimes: this.form.your_freetimes.join(', '),
                your_sextoy: this.form.your_sextoy,
                your_cosplay: this.form.your_cosplay,
                age_range: this.form.age_range,
                appear_points: this.appear_points_result,
                propensity: this.form.propensity,
                body_type_range: this.form.body_type_range,
              },
            },
          },
        }).catch((e) => {
          e
        })

        if (!result.data.success) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin()
          this.message = '入力内容を確認してください'
          return
        }
        if (result.data.success) {
          this.post_content = ''
          this.getAccountData()
          // this.$router.push("home");
          this.$router.push('/maletutorial')
          this.message = '更新しました'
        }
      },
    },
  }
</script>
