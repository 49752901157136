<template>
  <v-app>
    <div v-if="isLoading" style="padding-top: 60px">
      <Spinner></Spinner>
    </div>
    <div class="profile container" v-else>
      <div v-if="!purchase_flag">
        <transition name="bounce">
          <div class="flash" v-show="message_flag">
            <span>
              <p v-show="message_flag">{{ message }}</p>
            </span>
          </div>
        </transition>
        <div class="profile-area"></div>
        <div class="img">
          <img
            class="user-thumbnail"
            style="max-width: 390px"
            :src="profile.profile1"
            onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
          />
        </div>
        <div class="row" v-if="profile.like_remain > 0">
          <div class="col-md-12">
            <button class="btn-comp login-btn" @click="sendLike()">いいねを送る</button>
          </div>
        </div>
        <v-dialog v-model="dialog_show" width="500" @input="handleClose">
          <div style="background: white; color: #222222; padding: 20px">
            <p class="matching-success">いいね👍</p>
            <p>いいねを送りました！</p>
          </div>
        </v-dialog>

        <div class="form-contents-area">
          <div class="white-card">
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>年齢</p>
              </div>
              <div class="content">
                <p>{{ profile.age }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>地域</p>
              </div>
              <div class="content">
                <p v-for="area in profile.area" :key="area">{{ area }}</p>
              </div>
            </div>
            <div class="cont-wrapper" v-if="sex == 2">
              <div class="cont-title">
                <p>身長</p>
              </div>
              <div class="content">
                <p>{{ profile.height }}</p>
              </div>
            </div>
            <div class="cont-wrapper" v-if="sex == 2">
              <div class="cont-title">
                <p>体重</p>
              </div>
              <div class="content">
                <p>{{ profile.weight }}</p>
              </div>
            </div>
            <div class="cont-wrapper no-border">
              <div class="cont-title">
                <p>体型</p>
              </div>
              <div class="content">
                <p>{{ profile.body_type }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="form-contents-area">
          <div class="white-card">
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>撮影</p>
              </div>
              <div class="content">
                <p>{{ profile.takemovie }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>DM</p>
              </div>
              <div class="content">
                <p>{{ profile.your_pre_dm }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>会う前の電話</p>
              </div>
              <div class="content">
                <p>{{ profile.your_pre_tel }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>会う前の写真交換</p>
              </div>
              <div class="content">
                <p>{{ profile.your_pre_photo }}</p>
              </div>
            </div>
            <div class="cont-wrapper no-border">
              <div class="cont-title">
                <p>連絡先交換</p>
              </div>
              <div class="content">
                <p>{{ profile.your_pre_contact }}</p>
              </div>
            </div>
            <div class="cont-wrapper no-border">
              <div class="cont-title">
                <p>
                  都合のつきやすい
                  <br />
                  タイミング
                </p>
              </div>
              <div class="content">
                <p>{{ profile.your_freetimes }}</p>
              </div>
            </div>
          </div>
          <div class="white-card">
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>アピールポイント</p>
              </div>
              <div class="content">
                <p v-for="ap in profile.appear_points" :key="ap">{{ ap }}</p>
              </div>
            </div>

            <div class="cont-wrapper">
              <div class="cont-title">
                <p>フェラ</p>
              </div>
              <div class="content">
                <p>{{ profile.your_blow_job }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>クンニ</p>
              </div>
              <div class="content">
                <p>{{ profile.your_cunnilingus }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>首しめ</p>
              </div>
              <div class="content">
                <p>{{ profile.your_choking }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>拘束</p>
              </div>
              <div class="content">
                <p>{{ profile.your_kousoku }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>噛み跡</p>
              </div>
              <div class="content">
                <p>{{ profile.your_kisuma }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>脳イキ</p>
              </div>
              <div class="content">
                <p>{{ profile.your_nouiki }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>アナルセックス</p>
              </div>
              <div class="content">
                <p>{{ profile.your_anal_sex }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>優しい言葉攻め</p>
              </div>
              <div class="content">
                <p>{{ profile.your_word_attack_easy }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>激しい言葉攻め</p>
              </div>
              <div class="content">
                <p>{{ profile.your_word_attack_hard }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>スパンキング</p>
              </div>
              <div class="content">
                <p>{{ profile.your_spanking }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>野外プレイ</p>
              </div>
              <div class="content">
                <p>{{ profile.your_wild }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>潮吹き</p>
              </div>
              <div class="content">
                <p>{{ profile.your_ejaculation }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>イラマチオ</p>
              </div>
              <div class="content">
                <p>{{ profile.your_irrumatio }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>複数人</p>
              </div>
              <div class="content">
                <p>{{ profile.your_multi }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>おもちゃ</p>
              </div>
              <div class="content">
                <p>{{ profile.your_sextoy }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>コスプレ</p>
              </div>
              <div class="content">
                <p>{{ profile.your_cosplay }}</p>
              </div>
            </div>
            <div class="cont-wrapper">
              <div class="cont-title">
                <p>S度</p>
              </div>
              <div class="content">
                <p>{{ profile.your_sadism }}</p>
              </div>
            </div>
            <div class="cont-wrapper no-border">
              <div class="cont-title">
                <p>M度</p>
              </div>
              <div class="content">
                <p>{{ profile.your_masochism }}</p>
              </div>
            </div>
            <div class="cont-wrapper no-border">
              <div class="cont-title">
                <p>エッチの特徴</p>
              </div>
              <div class="content">
                <p>{{ profile.sexual_experience }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p style="font-weight: 900">詳細プロフィール閲覧可能数は毎日3:00にリセットされます。</p>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
  .profile {
    margin-top: 80px;
    margin-bottom: 100px;
  }
  .expand-transition {
    transition: all 0.3s ease;
    height: 30px;
    padding: 10px;
    background-color: #eee;
    overflow: hidden;
  }
  .expand-enter,
  .expand-leave {
    height: 0;
    padding: 0 10px;
    opacity: 0;
  }
  .matching-success {
    font-size: 3rem;
    font-weight: 800;
    color: #ff8c00;
    background: -webkit-linear-gradient(0deg, #8350de, #df468b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .heart-icon {
    font-size: 3rem;
    font-weight: 800;
    color: #df468b;
    background: -webkit-linear-gradient(0deg, #8350de, #df468b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .heart-broken-icon {
    font-size: 3rem;
    font-weight: 800;
    color: #3a3687;
    background: -webkit-linear-gradient(0deg, #8350de, #df468b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .v-input {
    margin-left: 15px;
  }
  .cont-title {
    width: 150px;
    font-weight: 900;
  }
  .content {
  }
  .no-border {
    border: none !important;
  }
  .cont-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /*justify-content:space-between;*/
    padding: 5px;
    border-bottom: 1px dotted #d3d3d3;
  }
  .profile-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .form-contents-area {
    margin-top: 15px;
  }
  .name {
    font-size: 1.3rem;
    font-weight: 900;
  }
  p {
    margin: 0;
  }
  .name-area {
    text-align: left;
  }

  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    /*margin-top: 30px;*/
    padding: 8px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }

  .signin-btn {
    background: #0081e8;
    margin-top: 3px;
  }
  .copy-btn {
    background: #775ddf;
    margin-top: 3px;
    font-size: 0.5rem;
  }
  label {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 30px;
  }
  .user-thumbnail {
    width: 100%; /* サムネイルの幅を調整 */
    height: 100%; /* サムネイルの高さを調整 */
    object-fit: cover; /* サムネイルをアスペクト比を保ったまま表示 */
    border-radius: 5px; /* サムネイルを円形にする */
    margin-right: 10px; /* サムネイルとユーザー名の間の余白 */
  }
</style>

<script>
  import axios from '../../util/axios'
  import Spinner from 'vue-simple-spinner'
  import 'vue-awesome/icons'

  export default {
    name: 'Profile',

    data() {
      return {
        uid: '',
        client: '',
        access_token: '',
        sex: '',
        partner_uuid: this.$route.query.uuid,
        profile: [],
        my_profile: [],
        message: '',
        message_flag: false,
        rec: [],
        template_text: '',
        dialog_show: false,
        data_all: null,
        isLoading: false,
        showNGReason: false,
        ngReason: '',
        ng_reason_other: '',
        ng_reason_bodytype: [],
        purchase_flag: false,
        ng_reason_limit_height: '',
        bodytype_list: ['痩せ型', '普通', 'マッチョ', 'ぽっちゃり', 'かなりぽっちゃり'],
        limit_height_list: ['気にしない', '160cm', '165cm', '170cm', '175cm'],
        ng_ok: '',
        showReply: false,
        noReply: false,
        showReviewButton: false,
        matchingDialog: false,
        noMatchingDialog: false,
        textRules: {
          maxLength: (v) => v && v.length <= 50,
        },
        result_status: '',
      }
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.sex = localStorage.getItem('sex')
      this.getAccountData()
      if (this.uid === null) {
        this.$router.push('/login')
      }
    },
    components: {
      Spinner,
    },
    computed: {
      is_postable() {
        if (this.ngReason != '') {
          if (this.ngReason == '体型（身長、体重）') {
            if (this.ng_reason_bodytype.length != 0 || this.ng_reason_limit_height != '') {
              return true
            } else {
              return false
            }
          } else if (this.ngReason == 'その他') {
            if (this.ng_reason_other != '') {
              if (this.ng_reason_other.length <= 50) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      },
    },
    methods: {
      checkMessageFlag() {
        setTimeout(() => {
          this.message_flag = true
        }, 1000)
        setTimeout(() => {
          this.message_flag = false
        }, 5000)
      },
      setMessage() {
        this.message = '自己紹介メッセージをコピーしました。'
      },

      copy_text() {
        navigator.clipboard
          .writeText(this.make_text())
          .then(() => {})
          .catch((e) => {
            console.error(e)
          })
      },

      async getAccountData() {
        this.isLoading = true
        const result = await axios({
          method: 'POST',
          url: '/api/subprofile',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            user: {
              uid: this.uid,
              partner_uuid: this.partner_uuid,
            },
          },
        }).catch((e) => {
          e
          this.$router.push('/login')
        })

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.$router.push('/login')
          this.isLoading = false
          return
        }
        if (!result.data.success) {
          this.isLoading = false
          this.purchase_flag = true
        }

        if (result.data.success) {
          this.isLoading = false
          this.purchase_flag = false
        }
        this.profile = result.data.profile

        this.data_all = result.data
      },

      make_text() {
        return (
          '初めまして！' +
          '\n' +
          '\n' +
          '性癖ちゃんからのレコメンドでいいなと思ったのでDMお送りしました！' +
          '\n' +
          'もしよければ仲良くしてくれると嬉しいです✨' +
          '\n' +
          '\n' +
          '\n' +
          '◆アカウント名' +
          '\n' +
          this.my_profile.twitter_name +
          '\n' +
          '\n' +
          '◆TwitterID' +
          '\n' +
          this.my_profile.twitterid +
          '\n' +
          '\n' +
          '◆年齢' +
          '\n' +
          this.my_profile.age +
          '\n' +
          '\n' +
          '◆活動範囲' +
          '\n' +
          this.my_profile.area +
          '\n' +
          '\n' +
          '◆体型' +
          '\n' +
          this.my_profile.body_type +
          '\n' +
          '\n' +
          '◆長所' +
          '\n' +
          this.my_profile.appear_points +
          '\n' +
          '\n' +
          '◆動画の撮影' +
          '\n' +
          this.my_profile.takemovie +
          '\n' +
          '\n' +
          '◆理想的なエッチ' +
          '\n' +
          this.my_profile.sexual_experience +
          '\n' +
          '\n' +
          '【コンタクト】' +
          '\n' +
          '◆DM' +
          '\n' +
          this.my_profile.your_pre_dm +
          '\n' +
          '\n' +
          '◆会う前の電話' +
          '\n' +
          this.my_profile.your_pre_tel +
          '\n' +
          '\n' +
          '◆会う前の写真交換' +
          '\n' +
          this.my_profile.your_pre_photo +
          '\n' +
          '\n' +
          '◆連絡先交換' +
          '\n' +
          this.my_profile.your_pre_contact +
          '\n' +
          '\n' +
          '◆都合のつきやすいタイミング' +
          '\n' +
          this.my_profile.your_freetimes +
          '\n' +
          '\n' +
          '【性癖】' +
          '\n' +
          '◆フェラ' +
          '\n' +
          this.my_profile.your_blow_job +
          '\n' +
          '\n' +
          '◆クンニ' +
          '\n' +
          this.my_profile.your_cunnilingus +
          '\n' +
          '\n' +
          '◆首絞め' +
          '\n' +
          this.my_profile.your_choking +
          '\n' +
          '\n' +
          '◆拘束' +
          '\n' +
          this.my_profile.your_kousoku +
          '\n' +
          '\n' +
          '◆噛み跡' +
          '\n' +
          this.my_profile.your_kisuma +
          '\n' +
          '\n' +
          '◆脳イキ' +
          '\n' +
          this.my_profile.your_nouiki +
          '\n' +
          '\n' +
          '◆アナル' +
          '\n' +
          this.my_profile.your_anal_sex +
          '\n' +
          '\n' +
          '◆優しい言葉責め' +
          '\n' +
          this.my_profile.your_word_attack_easy +
          '\n' +
          '\n' +
          '強い言葉責め' +
          '\n' +
          this.my_profile.your_word_attack_hard +
          '\n' +
          '\n' +
          '◆スパンキング' +
          '\n' +
          this.my_profile.your_spanking +
          '\n' +
          '\n' +
          '◆野外' +
          '\n' +
          this.my_profile.your_wild +
          '\n' +
          '\n' +
          '◆潮吹き' +
          '\n' +
          this.my_profile.your_ejaculation +
          '\n' +
          '\n' +
          '◆イラマチオ' +
          '\n' +
          this.my_profile.your_irrumatio +
          '\n' +
          '\n' +
          '◆複数プレイ' +
          '\n' +
          this.my_profile.your_multi +
          '\n' +
          '\n' +
          '◆コスプレ' +
          '\n' +
          this.my_profile.your_cosplay +
          '\n' +
          '\n' +
          '◆おもちゃ' +
          '\n' +
          this.my_profile.your_sextoy +
          '\n' +
          '\n' +
          '◆私のS度' +
          '\n' +
          this.my_profile.your_sadism +
          '\n' +
          '\n' +
          '◆私のM度' +
          '\n' +
          this.my_profile.your_masochism
        )
      },
      async sendLike() {
        const result = await axios({
          method: 'POST',
          url: '/api/send_like',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            // recommend:{
            uuid: this.partner_uuid,
            // }
          },
        }).catch((e) => {
          e
          //   this.$router.push('/login');
        })
        if (result.data.success) {
          this.result_status = true
          this.dialog_show = !this.dialog_show
        }
      },
      handleClose() {
        this.$router.push('/timeline')
      },
    },
  }
</script>
