<template>
  <div :class="['chat-bubble', isUser ? 'user' : 'other']">
    <div class="message"><span v-html="message"></span></div>
  </div>
</template>

<script>
  export default {
    name: 'ChatBubble',
    props: {
      message: {
        type: String,
        required: true,
      },
      isUser: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped>
  .chat-view {
    display: flex;
    /* 親要素を flex コンテナに変更 */
    flex-direction: column;
    /* 子要素を縦方向に配置 */
    align-items: flex-start;
    /* 子要素を左端に配置 */
    padding: 16px;
  }

  .message {
    max-width: 100%;
    text-align: left;
  }

  .chat-bubble-wrapper {
    display: flex;
    flex-shrink: 0;
    /* チャットバブルが画面いっぱいに広がるのを防止 */
    justify-content: flex-end;
    /* 子要素を右端に配置 */
    width: 100%;
    /* 幅いっぱいに拡張 */
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .chat-bubble {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 16px;
    word-wrap: break-word;
    max-width: 70%;
    /* メッセージの最大幅を指定 */
  }

  .chat-bubble.user {
    background-color: #4caf50;
    color: white;
  }

  .chat-bubble.other {
    background-color: Ivory;
    color: #333333;
    align-self: flex-start;
    /* 他のメッセージを左寄せ */
  }
</style>

<script>
  export default {
    name: 'ChatBubble',
    props: {
      message: {
        type: String,
        required: true,
      },
      isUser: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped>
  .chat-bubble {
    display: inline-block;
    margin-bottom: 8px;
    padding: 8px 12px;
    border-radius: 16px;
    max-width: 70%;
    /* メッセージの最大幅を指定 */
  }

  .chat-bubble.user {
    background-color: #9256d3;
    color: white;
    align-self: flex-end;
    /* ユーザーメッセージを右寄せ */
  }

  .chat-bubble.other {
    background-color: Ivory;
    color: #333333;
    align-self: flex-start;
    /* 他のメッセージを左寄せ */
  }
</style>
