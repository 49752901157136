<template>
  <div class="signup">
    <div class="container" style="text-align: center">
      <div v-show="!isLoading">{{ this.message }}</div>
      <div style="text-align: left">
        <label>メールアドレス</label>
        <br />
        <p v-show="!isInValidEmail">
          正しいメールアドレスの形式で入力してください。
          <br />
          <span class="mail-domain-notification-text">
            ※キャリアメールでは利用できないことがあるので、GmailやYahoo!などのフリーアドレスを推奨しています。
          </span>
        </p>
        <input type="text" v-model="email" placeholder="メールアドレスを入力してください" required />
        <br />
        <label>パスワード</label>
        <br />
        <p v-show="password.length < 6">6文字以上で入力してください。</p>
        <input type="password" v-model="password" placeholder="パスワードを入力してください" required />
        <br />
        <label>パスワード（確認用）</label>
        <br />
        <input type="password" v-model="password_confirmation" placeholder="パスワードを入力してください（確認用）" required />
        <br />

        <label>性別</label>
        <br />
        <select name="sex" v-model="sex" required>
          <option value="" disabled selected style="display: none; color: gray">選択してください</option>
          <option value="1">男性</option>
          <option value="2">女性</option>
        </select>
        <p></p>
      </div>
      <label for="confirmation" style="font-size: 1rem">確認</label>

      <p>
        <a href="https://service.seiheki-matching.jp/MEN_userpolicy" target="_blank" style="border-bottom: 1px solid gray">利用規約</a>
        と
        <a href="https://service.seiheki-matching.jp/MEN_policy" target="_blank" style="border-bottom: 1px solid gray">プライバシーポリシー</a>
        に同意します。
        <input type="checkbox" name="confirmation" v-model="confirmation_flag" style="max-width: 20px" />
      </p>
      <div v-show="isLoading" style="padding-top: 60px">
        <Spinner></Spinner>
      </div>
      <p>下記のボタンを押すとinfo@itonami.designより認証メールが届きます。</p>
      <button @click="signup()" :disabled="!confirmation_flag && isInValidEmail" type="submit" class="signin-btn btn-comp">会員登録</button>
    </div>
  </div>
</template>

<style scoped>
  .mail-domain-notification-text {
    color: red;
  }
  .form-group {
    text-align: left;
  }

  label {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 30px;
  }
  input {
    margin-bottom: 30px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }
  select {
    margin-bottom: 30px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }

  a {
    color: #4b4b4b;
  }
  a:hover {
    color: #4b4b4b;
  }
  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }

  .signin-btn {
    background: rgb(97, 34, 219);
    background: linear-gradient(90deg, rgba(97, 34, 219, 1) 0%, rgba(91, 103, 229, 1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text {
    font-size: 0.8rem;
    padding-top: 20px;
  }
  .get-account-information-text {
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  .twitter_id_err {
    margin-top: -30px;
    font-weight: bold;
    color: red;
  }
  .twitter_id_ok {
    margin-top: -30px;
    font-weight: bold;
    color: green;
  }
</style>

<script>
  import axios from '../../util/axios'
  // const qs = require("qs");
  import Spinner from 'vue-simple-spinner'
  export default {
    name: 'singup',
    data: function () {
      return {
        uid: '',
        client: '',
        access_token: '',
        email: '',
        password: '',
        password_confirmation: '',
        sex: '',
        message: '',
        confirmation_flag: false,
        isLoading: false,
        code: '',
        af_code: '',
        did: '',
        adnex_cid: '',
        adnex_p: '',
      }
    },
    mounted() {
      this.code = this.$route.query.code
      this.af_code = this.$route.query.afad
      this.did = this.$route.query.did
      this.adnex_cid = this.$route.query.cid
      this.adnex_p = this.$route.query.p
      this.$nextTick(function () {
        window.scrollTo(top, 0)
      })
    },
    components: {
      Spinner,
    },
    computed: {
      isInValidEmail() {
        if (this.email.length == 0) {
          return false
        } else {
          const reg = new RegExp(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)
          if (!reg.test(this.email)) {
            return false
          } else {
            return true
          }
        }
      },
    },
    methods: {
      async signup() {
        this.isLoading = true
        const result = await axios
          .post('/api/auth', {
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            sex: this.sex,
            code: this.code,
            afad: this.af_code,
            did: this.did,
            cid: this.adnex_cid,
            p: this.adnex_p,
          })
          .catch((e) => {
            e
          })

        if (!result) {
          this.message = 'メールアドレスとパスワードを確認してください。'
          this.isLoading = false
          return
        }
        if (!result.data) {
          this.message = 'メールアドレスとパスワードを確認してください。'
          this.isLoading = false
          return
        }

        if (result.data) {
          //結果を基にページ遷移
          this.isLoading = false
          localStorage.setItem('access-token', result.headers['access-token'])
          localStorage.setItem('client', result.headers['client'])
          localStorage.setItem('uid', result.headers['uid'])
          if (result.data.sex == 2) {
            this.$router.push('/after_sign_up?id=' + result.data.id)
          } else {
            this.$router.push({ name: 'Login', query: { send_mail: true } })
          }
        }
      },
    },
  }
</script>
