<template>
  <v-app>
    <div class="Topic container" style="margin-top: 80px; margin-bottom: 80px">
      <div class="row">
        <div class="col-4">
          <router-link to="topics">
            <button class="topic-sex-btn" @click="switch_com()">男女共通</button>
          </router-link>
        </div>
        <div class="col-4">
          <router-link to="topics?target=male">
            <button class="topic-sex-btn-male" @click="switch_male()">
              男性専用
              <span class="topic-sex-btn-subj-male" v-if="sex == 2">
                <br />
                （女性は閲覧のみ）
              </span>
            </button>
          </router-link>
        </div>
        <div class="col-4">
          <router-link to="topics?target=female">
            <button class="topic-sex-btn-female" @click="switch_female()">
              女性専用
              <span class="topic-sex-btn-subj-female" v-if="sex == 1">
                <br />
                （男性は閲覧のみ）
              </span>
            </button>
          </router-link>
        </div>
      </div>

      <div class="container white-card topic-board">
        <p>{{ topic.created_at | moment }}</p>
        <p style="font-size: 1.2rem; font-weight: 900; border-bottom: 1px dotted #d3d3d3">
          {{ topic.name }}
        </p>
        <div v-for="post in posts" :key="post.id" class="">
          <div class="post-wrapper">
            <div class="left-area">
              <Icon name="user" scale="2" style="color: white" class="icon-male" v-if="post.sender_sex == 1" />
              <Icon name="user" scale="2" style="color: white" class="icon-female" v-if="post.sender_sex == 2" />
            </div>
            <div class="mid-area">
              <p class="timestamp">{{ post.created_at | moment }}</p>

              <p style="margin-bottom: 0">{{ post.content }}</p>
              <p v-show="!post.already_liked">
                <button @click="create_like(post)">
                  <Icon name="thumbs-up" scale="1" style="color: #dddddd; margin-bottom: 0" class="" />
                </button>
                {{ post.liked_count }}
              </p>
              <p v-show="post.already_liked">
                <button @click="delete_like(post)">
                  <Icon name="thumbs-up" scale="1" style="color: pink; margin-bottom: 0" class="" />
                </button>
                {{ post.liked_count }}
              </p>
              <p v-if="post.mypost" @click="post_delete(post.id)" style="font-size: 0.65rem">投稿を削除</p>
            </div>
          </div>
          <div class="good-area"></div>
        </div>
      </div>

      <div v-show="isPostable">
        <p style="margin-bottom: 0; margin: 10px; font-weight: 800">
          投稿内容は弊社の外部コンテンツ(Instagram等）に使用させていただく場合がございます
        </p>

        <textarea
          v-model="post_content"
          style="background: white; max-width: 400px; width: 100%; padding: 10px; border-radius: 5px"
          placeholder="コメントを入力してください"
        ></textarea>
        <button class="btn-comp login-btn" @click="post()" v-show="!isLoading">
          書き込む
          <Icon name="pencil-alt" scale="2" style="color: white" class="icon-pencil" />
        </button>
        <div v-if="isLoading" style="padding-top: 30px">
          <Spinner></Spinner>
        </div>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
  .good-area {
    padding: 5px;
  }
  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }
  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 10px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  .topic-sex-btn {
    background: white;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #775ddf;
    font-weight: 800;
    color: #775ddf;
    position: relative;
  }
  .topic-sex-btn-male {
    background: white;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #0054df;
    font-weight: 800;
    color: #0054df;
    position: relative;
  }
  .topic-sex-btn-female {
    background: white;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #b81c95;
    font-weight: 800;
    color: #b81c95;
    position: relative;
  }
  .topic-sex-btn-subj {
    font-size: 0.6rem;
    position: absolute;
    margin: auto;
    font-weight: 400;
    color: #775ddf;
    bottom: 5px;
    left: 0;
    right: 0;
  }
  .topic-sex-btn-subj-male {
    font-size: 0.6rem;
    position: absolute;
    margin: auto;
    font-weight: 400;
    color: #0054df;
    bottom: 5px;
    left: 0;
    right: 0;
  }
  .topic-sex-btn-subj-female {
    font-size: 0.6rem;
    position: absolute;
    margin: auto;
    font-weight: 400;
    color: #b81c95;
    bottom: 5px;
    left: 0;
    right: 0;
  }
  .timestamp {
    margin: 0;
    font-size: 0.6rem;
    color: gray;
  }
  .topic-board {
    text-align: left;
    margin-top: 30px;
  }
  .post-wrapper {
    text-align: left;
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /*box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);*/
    vertical-align: middle;
    position: relative;
    border-bottom: dotted 1px #d3d3d3;
  }
  .icon-male {
    background: #5668e8;
    padding: 8px 3px;
    border-radius: 3px;
    width: 30px;
  }
  .icon-female {
    background: #c240a2;
    padding: 8px 3px;
    border-radius: 3px;
    width: 30px;
  }
  .icon-pencil {
    padding-top: 8px;
    padding-bottom: 2px;
  }
  .mid-area {
    flex-grow: 8;
    width: 85%;
  }

  .left-area {
    width: calc(50px);
    flex-grow: 1;
    vertical-align: middle;
    justify-content: center;
    display: flex;
    align-items: center;
  }
</style>
<script>
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'
  import axios from '../../util/axios'
  import moment from 'moment'
  import Spinner from 'vue-simple-spinner'
  export default {
    name: 'Topic',
    // props:["topicid"],
    components: {
      Icon,
      Spinner,
    },
    filters: {
      moment: function (date) {
        return moment(date).format('YYYY/MM/DD H:mm')
      },
    },
    data: function () {
      return {
        uid: '',
        client: '',
        access_token: '',
        topicid: this.$route.query.topicid,
        all_data: '',
        sex: '',
        topic: [],
        posts: [],
        post_content: '',
        user_id: '',
        isLoading: false,
      }
    },
    computed: {
      isPostable: function () {
        if (this.sex == 1) {
          if (this.topic.sex == 'male' || this.topic.sex == 'common') {
            return true
          } else {
            return false
          }
        } else if (this.sex == 2) {
          if (this.topic.sex == 'female' || this.topic.sex == 'common') {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      },
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData()
      if (this.uid === null) {
        this.$router.push('/login')
      }
    },
    methods: {
      async create_like(post) {
        const result = await axios
          .post('/api/post_likes', {
            // const result = await axios.post("/api/recommend_likes", {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
            uid: this.uid,
            post_id: post.id,
          })
          .catch((e) => {
            e
          })
        post.liked_count += 1
        post.already_liked = true
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return
        }
      },
      async delete_like(post) {
        const result = await axios
          .delete('/api/post_likes', {
            // const result = await axios.delete("/api/recommend_likes", {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
            params: {
              uid: this.uid,
              post_id: post.id,
            },
          })
          .catch((e) => {
            e
          })
        post.liked_count -= 1
        post.already_liked = false
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return
        }
      },
      async post_delete(id) {
        const result = await axios
          .delete('/api/post', {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
            data: {
              post_id: id,
              user_id: this.uid,
            },
          })
          .catch((e) => {
            e
          })
        result
        this.$router.go({ path: this.$router.currentRoute.path, force: true })
      },
      async post() {
        this.isLoading = true
        const result = await axios
          .post('/api/post/create', {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
            post: {
              topic_id: this.topic.id,
              user_id: this.user_id,
              content: this.post_content,
              status: 0,
            },
          })
          .catch((e) => {
            e
          })

        if (!result) {
          this.isLoading = false
          return
        }
        if (result) {
          this.isLoading = false
          this.post_content = ''
          this.getAccountData()
        }
      },
      async getAccountData() {
        const result = await axios
          .get('/api/topic/show', {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
            params: {
              topicid: this.topicid,
            },
          })
          .catch((e) => {
            e
          })

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return
        }

        this.all_data = result.data
        this.sex = result.data.sex
        this.posts = result.data.posts
        this.topic = result.data.topic
        this.sex = result.data.sex
        this.user_id = result.data.id

        // this.twitterid = result.data.twitterid;
      },
    },
  }
</script>
