<template>
  <div id="app">
    <v-app>
      <Header v-if="$showHeader" />
      <div class="page-wrapper">
        <transition>
          <router-view />
        </transition>
      </div>

      <v-footer color="white" class="fixed-bottom" v-if="$showFooter" style="max-width: 100vw">
        <v-spacer></v-spacer>
        <div class="footer-icons">
          <v-btn icon href="/home" class="footer-icon">
            <v-icon>mdi-home</v-icon>
          </v-btn>
          <v-divider vertical class="footer-divider"></v-divider>
          <v-btn icon href="/recommends/male?status=matching" class="footer-icon" v-if="sex == 1">
            <v-icon>mdi-chat-processing</v-icon>
            <v-badge v-if="unread_messages_size > 0" dot color="pink"></v-badge>
          </v-btn>
          <v-btn icon href="/recommends?status=matching" class="footer-icon" v-else>
            <v-badge v-if="unread_messages_size > 0" dot color="pink"></v-badge>
            <v-icon>mdi-chat-processing</v-icon>
          </v-btn>
          <v-divider vertical class="footer-divider"></v-divider>
          <v-btn icon href="/timeline" class="footer-icon">
            <v-icon>mdi-account-box-multiple</v-icon>
          </v-btn>
          <v-divider vertical class="footer-divider"></v-divider>
          <v-btn icon href="/form" class="footer-icon" v-if="sex == 1">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
          <v-btn icon href="/femaleform" class="footer-icon" v-if="sex == 2">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
  import 'normalize.css'
  import 'material-design-icons'
  import 'vue-loaders/dist/vue-loaders.css'
  import Header from '@/components/Header.vue'

  export default {
    name: 'App',
    created: function () {
      document.documentElement.setAttribute('lang', 'ja')
    },
    data() {
      return {
        sex: '',
      }
    },
    methods: {
      createTitleDesc: function (routeInstance) {
        if (routeInstance.meta.title) {
          var setTitle = routeInstance.meta.title + ' | 性癖マッチン'
          document.title = setTitle
        } else {
          document.title = '性癖マッチン'
        }
      },
      redirectLogin() {
        this.$router.push('/login')
      },
    },

    mounted: function () {
      var routeInstance = this.$route
      this.createTitleDesc(routeInstance)
      this.sex = localStorage.getItem('sex')
      window.scrollTo({ top: 0 })
    },
    watch: {
      $route(nv, ov) {
        nv
        ov
        scrollTo(0, 0) // トップに移動
        this.$nextTick(() => {
          scrollTo(0, 0) // トップに移動
        })
      },
    },
    computed: {
      unread_messages_size() {
        return this.$store.state.unread_messages_size
      },
    },
    components: {
      Header,
    },
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
  .footer-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 56px;
    width: 100%;
  }

  .footer-icon {
    min-width: 0;
    flex: 1;
    padding: 6px;
  }

  .footer-divider {
    background-color: rgba(255, 255, 255, 0.82);
    margin: 0 8px;
  }

  .fixed-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1 !important;
    /* background-color: rgba(0, 0, 255, 1) !important; */
  }

  @media (max-width: 480px) {
    /* .page-wrapper{
    margin-top: 50px;
    max-height: calc(100vh - 50px);
  } */
    .breadcrumb {
      display: none;
    }

    #app {
      font-family: 'Avenir', Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      min-height: calc(100vh - 50px);
      background: #e7e7f3;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 480px) {
    .page-wrapper {
      margin-top: 80px;
      height: 100%;
    }
    #app {
      font-family: 'Avenir', Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      min-height: calc(100vh - 80px);
      background: #e7e7f3;
      width: 100%;
      height: 100%;
    }
    .breadcrumb {
    }
  }

  body {
    background: #e7e7f3;
    width: 100%;
    height: 100%;
  }
  a {
    color: #4b4b4b;
    font-size: 0.85rem;
  }
  a:hover {
    text-decoration: none !important;
  }

  p {
    color: #4b4b4b;
    font-size: 0.85rem;
  }
  h2 {
    border-left: 5px solid;
    border-bottom: none;
    border-top: none;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 1;
    padding-left: 10px;
    font-size: 1rem !important;
    font-weight: 900 !important;
    color: #4b4b4b;
    text-align: left;
    margin-top: 50px !important;
  }

  .content-wrapper {
    margin-top: 50px;
  }
  .flash {
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    margin: auto;
    background: rgba(255, 255, 255, 1);
    font-size: 1.4rem;
    /*border-radius: 5px;*/
    padding: 10px;
    border-top: 2px solid #ff8588;
    border-bottom: 2px solid #ff8588;
  }
  .flash-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
  .v-enter {
    transform: translate(-100px, 0);
    opacity: 0;
  }
  .v-enter-to {
    opacity: 1;
  }
  .v-enter-active {
    transition: all 0.8s 0s ease;
  }
  .v-leave {
    transform: translate(0, 0);
    opacity: 1;
  }
  .v-leave-to {
    transform: translate(100px, 0);
    opacity: 0;
  }
  .v-leave-active {
    transition: all 0.5s 0s ease;
  }

  .v-input--checkbox .v-label {
    font-size: 13px;
  }
  .v-radio .v-label {
    font-size: 13px;
  }
  .v-input__slot .v-label {
    font-size: 13px;
  }
  .v-badge {
    margin-bottom: 10px;
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }
  .v-application a {
    color: #333333 !important;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
