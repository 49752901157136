<template>
  <div class="Preview"></div>
</template>

<script>
  export default {
    name: 'Preview',
    components: {},
  }
</script>
