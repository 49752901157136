<template>
  <Login />
</template>

<script>
  import Login from '@/views/Login.vue'
  export default {
    name: 'Index',
    components: {
      Login,
    },
  }
</script>
