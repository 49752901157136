<template>
  <v-app>
    <div v-if="isLoading" style="padding-top: 60px">
      <Spinner></Spinner>
    </div>
    <div class="Profile container" v-else style="margin-bottom: 60px; margin-top: 60px">
      <transition name="bounce">
        <div class="flash" v-show="message_flag">
          <span>
            <p v-show="message_flag">{{ message }}</p>
          </span>
        </div>
      </transition>
      <carousel autoplay="false" loop="false" :per-page="1">
        <slide>
          <div class="slider-inner">
            <img
              class="user-thumbnail"
              style="max-width: 390px"
              :src="profile.profile1"
              onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
            />
          </div>
        </slide>
        <slide v-if="profile.profile2 != '/images/fallback/default.png'">
          <div class="slider-inner">
            <img
              class="user-thumbnail"
              style="max-width: 390px"
              :src="profile.profile2"
              onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
            />
          </div>
        </slide>
        <slide v-if="profile.profile3 != '/images/fallback/default.png'">
          <div class="slider-inner">
            <img
              class="user-thumbnail"
              style="max-width: 390px"
              :src="profile.profile3"
              onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
            />
          </div>
        </slide>
      </carousel>
      <div class="profile-area">
        <div class="name-area" v-show="this.rec.status_n != 8">
          <p class="name">{{ profile.twitter_name }}</p>
        </div>
      </div>
      <v-dialog v-model="matchingDialog" width="500">
        <div style="background: white; color: #222222; padding: 20px">
          <transition name="expand">
            <Icon name="heart" scale="5" style="" class="heart-icon" />
          </transition>
          <p class="matching-success">マッチン成立</p>
          <p>マッチンおめでとうございます！</p>
        </div>
      </v-dialog>
      <div class="row" v-show="this.rec.status_n != 9 && this.rec.status_n != 8" style="margin-bottom: 5px">
        <div class="col-md-6">
          <router-link :to="{ name: 'chat', query: { uuid: this.profile.uuid } }">
            <button class="btn-comp login-btn" style="margin-top: 0 !important">チャットする</button>
          </router-link>
          ---------------------------
        </div>
      </div>
      <div v-show="this.rec.status_n == 9">
        <Transition>
          <div
            class="row"
            style="justify-content: space-around; padding-top: 15px; padding-bottom: 45px"
            v-show="!showNGReason || ng_ok == 'suspend'"
          >
            <div class="col-12">
              <p style="font-weight: 900">マッチン、ノーマッチンを選んでください。</p>
            </div>
            <div class="col-6">
              <button
                class="btn-comp signin-btn"
                style="font-size: 1rem; background: #db668e; width: 100%"
                @click="
                  showNGReason = !showNGReason
                  ng_ok = 'ok'
                  submitMatchingStatus('ok', '')
                  matchingDialog = !matchingDialog
                "
              >
                女性に連絡する
              </button>
            </div>
            <div class="col-6">
              <button style="font-size: 1rem; width: 100%" class="btn-comp signin-btn" @click="submitMatchingStatus('ng', 'ng')">
                女性に連絡しない
              </button>
            </div>
          </div>
        </Transition>
      </div>
      <div class="white-card" v-show="this.rec.status_n != 8">
        <div class="cont-wrapper">
          <div class="cont-title">
            <p>twitter</p>
          </div>
          <div class="content">
            <p v-if="profile.twitterid == null || profile.twitterid == ''">
              {{ profile.twitterid }}
            </p>
            <a :href="'https://twitter.com/' + profile.twitterid" target="_blank" v-else>{{ profile.twitterid }}</a>
          </div>
        </div>
        <div class="cont-wrapper">
          <div class="cont-title">
            <p>instagram</p>
          </div>
          <div class="content">
            <p v-if="profile.instagram == null || profile.instagram == ''">
              {{ profile.instagram }}
            </p>
            <a :href="'https://www.instagram.com/' + profile.instagram" target="_blank" v-else>{{ profile.instagram }}</a>
          </div>
        </div>
        <div class="cont-wrapper no-border">
          <div class="cont-title">
            <p>tiktok</p>
          </div>
          <div class="content">
            <p v-if="profile.tiktok == null || profile.tiktok == ''">
              {{ profile.tiktok }}
            </p>
            <a :href="'https://www.tiktok.com/@' + profile.tiktok" target="_blank" v-else>{{ profile.tiktok }}</a>
          </div>
        </div>
      </div>
      <div class="form-contents-area">
        <div class="white-card">
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>年齢</p>
            </div>
            <div class="content">
              <p>{{ profile.age }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>地域</p>
            </div>
            <div class="content">
              <p v-for="area in profile.area" :key="area">{{ area }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>体型</p>
            </div>
            <div class="content">
              <p>{{ profile.body_type }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="form-contents-area">
        <div class="white-card">
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>撮影</p>
            </div>
            <div class="content">
              <p>{{ profile.takemovie }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>DM</p>
            </div>
            <div class="content">
              <p>{{ profile.your_pre_dm }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>会う前の電話</p>
            </div>
            <div class="content">
              <p>{{ profile.your_pre_tel }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>会う前の写真交換</p>
            </div>
            <div class="content">
              <p>{{ profile.your_pre_photo }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>連絡先交換</p>
            </div>
            <div class="content">
              <p>{{ profile.your_pre_contact }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>
                都合のつきやすい
                <br />
                タイミング
              </p>
            </div>
            <div class="content">
              <p>{{ profile.your_freetimes }}</p>
            </div>
          </div>
        </div>
        <div class="white-card">
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>フェラ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_blow_job }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>クンニ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_cunnilingus }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>首しめ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_choking }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>拘束</p>
            </div>
            <div class="content">
              <p>{{ profile.your_kousoku }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>噛み跡</p>
            </div>
            <div class="content">
              <p>{{ profile.your_kisuma }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>脳イキ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_nouiki }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>アナルセックス</p>
            </div>
            <div class="content">
              <p>{{ profile.your_anal_sex }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>優しい言葉攻め</p>
            </div>
            <div class="content">
              <p>{{ profile.your_word_attack_easy }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>激しい言葉攻め</p>
            </div>
            <div class="content">
              <p>{{ profile.your_word_attack_hard }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>スパンキング</p>
            </div>
            <div class="content">
              <p>{{ profile.your_spanking }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>野外プレイ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_wild }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>潮吹き</p>
            </div>
            <div class="content">
              <p>{{ profile.your_ejaculation }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>イラマチオ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_irrumatio }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>複数人</p>
            </div>
            <div class="content">
              <p>{{ profile.your_multi }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>おもちゃ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_sextoy }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>コスプレ</p>
            </div>
            <div class="content">
              <p>{{ profile.your_cosplay }}</p>
            </div>
          </div>
          <div class="cont-wrapper">
            <div class="cont-title">
              <p>S度</p>
            </div>
            <div class="content">
              <p>{{ profile.your_sadism }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>M度</p>
            </div>
            <div class="content">
              <p>{{ profile.your_masochism }}</p>
            </div>
          </div>
          <div class="cont-wrapper no-border">
            <div class="cont-title">
              <p>アピールポイント</p>
            </div>
            <div class="content">
              <p style="white-space: pre-line; text-align: left">
                {{ profile.appear_points }}
              </p>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-3 cont-title">
                <p>理想的なエッチ</p>
              </div>
              <div class="col-9" style="overflow-wrap: normal; width: 100%; white-space: pre-line; text-align: left; display: block">
                <p style="overflow-wrap: break-word; max-width: 100%; white-space: pre-line; text-align: left">
                  {{ profile.sexual_experience }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: 80px" v-if="this.rec.status_n != 9 && rec.status_n != 8">
          <div class="col-md-12">
            <button class="btn-comp login-btn" style="background: gray" @click="dialog_show = !dialog_show">ブロック</button>
          </div>
        </div>
        <v-dialog v-model="dialog_show" width="500">
          <div style="background: white; color: #222222; padding: 20px">
            <p class="matching-success">本当に解除しますか？</p>
            <p>解除したマッチンは復活できません。</p>
            <button class="btn-comp login-btn" style="background: gray" @click="submitMatchingStatus('ng', 'block')">ブロック</button>
          </div>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
  .expand-transition {
    transition: all 0.3s ease;
    height: 30px;
    padding: 10px;
    background-color: #eee;
    overflow: hidden;
  }
  .expand-enter,
  .expand-leave {
    height: 0;
    padding: 0 10px;
    opacity: 0;
  }
  .matching-success {
    font-size: 1.3rem;
    font-weight: 800;
    color: black;
  }
  .heart-icon {
    font-size: 3rem;
    font-weight: 800;
    color: #df468b;
    background: -webkit-linear-gradient(0deg, #8350de, #df468b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .heart-broken-icon {
    font-size: 3rem;
    font-weight: 800;
    color: #3a3687;
    background: -webkit-linear-gradient(0deg, #8350de, #df468b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .v-input {
    margin-left: 15px;
  }
  .cont-title {
    width: 150px;
    font-weight: 900;
  }
  .content {
  }
  .no-border {
    border: none !important;
  }
  .cont-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /*justify-content:space-between;*/
    padding: 5px;
    border-bottom: 1px dotted #d3d3d3;
  }
  .profile-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .form-contents-area {
    margin-top: 15px;
  }
  .name {
    font-size: 1.3rem;
    font-weight: 900;
  }
  p {
    margin: 0;
  }
  .name-area {
    text-align: left;
  }

  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    /*margin-top: 30px;*/
    padding: 8px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }

  .signin-btn {
    background: #0081e8;
    margin-top: 3px;
  }
  .copy-btn {
    background: #775ddf;
    margin-top: 3px;
    font-size: 0.5rem;
  }
  label {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 30px;
  }
  .user-thumbnail {
    width: 100%; /* サムネイルの幅を調整 */
    height: 100%; /* サムネイルの高さを調整 */
    object-fit: cover; /* サムネイルをアスペクト比を保ったまま表示 */
    border-radius: 5px; /* サムネイルを円形にする */
    margin-right: 10px; /* サムネイルとユーザー名の間の余白 */
  }
</style>

<script>
  import axios from '../../util/axios'
  import Spinner from 'vue-simple-spinner'
  import Icon from 'vue-awesome/components/Icon'
  import { Carousel, Slide } from 'vue-carousel'

  export default {
    name: 'Profile',

    data() {
      return {
        uid: '',
        client: '',
        access_token: '',
        partner_id: this.$route.query.id,
        profile: [],
        my_profile: [],
        message: '',
        message_flag: false,
        rec: [],
        template_text: '',
        data_all: null,
        isLoading: false,
        showNGReason: false,
        ngReason: '',
        ng_reason_other: '',
        ng_reason_bodytype: [],
        ng_reason_limit_height: '',
        dialog_show: false,
        bodytype_list: ['痩せ型', '普通', 'マッチョ', 'ぽっちゃり', 'かなりぽっちゃり'],
        limit_height_list: ['気にしない', '160cm', '165cm', '170cm', '175cm'],
        ng_ok: '',
        showReply: false,
        noReply: false,
        showReviewButton: false,
        matchingDialog: false,
        noMatchingDialog: false,
        textRules: {
          maxLength: (v) => v && v.length <= 50,
        },
      }
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData()
      if (this.uid === null) {
        this.$router.push('/login')
      }
    },
    components: {
      Spinner,
      Icon,
      Carousel,
      Slide,
    },
    computed: {
      is_postable() {
        if (this.ngReason != '') {
          if (this.ngReason == '体型（身長、体重）') {
            if (this.ng_reason_bodytype.length != 0 || this.ng_reason_limit_height != '') {
              return true
            } else {
              return false
            }
          } else if (this.ngReason == 'その他') {
            if (this.ng_reason_other != '') {
              if (this.ng_reason_other.length <= 50) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      },
    },
    methods: {
      checkMessageFlag() {
        setTimeout(() => {
          this.message_flag = true
        }, 1000)
        setTimeout(() => {
          this.message_flag = false
        }, 5000)
      },
      setMessage() {
        this.message = '自己紹介メッセージをコピーしました。'
      },
      isReviwable() {
        const status = this.rec.status_n

        if ((status == 1 || status == 2 || status == 7) && this.rec.is_reviewable) {
          return true
        } else {
          return false
        }
      },

      copy_text() {
        navigator.clipboard
          .writeText(this.make_text())
          .then(() => {})
          .catch((e) => {
            console.error(e)
          })
      },
      async submitMatchingStatus(m_status, action) {
        // this.isLoading = true;
        // if(this.ng_reason_other != ''){
        //   this.ngReason = this.ng_reason_other;
        // } else if (this.ng_reason_bodytype.length != 0 || this.ng_reason_limit_height != ''){
        // 	if(this.ng_reason_bodytype.length!=0){
        //     this.ngReason = 'NG体型:'+this.ng_reason_bodytype.join(',')
        // 	}
        //     if(this.ng_reason_limit_height != ''){
        //     	this.ngReason = this.ngReason +' 身長下限:'+this.ng_reason_limit_height
        //     }
        // }
        if (action == 'block') {
          this.ngReason = 'マッチン解除'
        } else if (action == 'ng') {
          this.ngReason = 'いいねNG'
        }
        const result = await axios({
          method: 'POST',
          url: '/api/matching_status',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            recommend: {
              id: this.rec.id,
              status: m_status,
              ng_reason: this.ngReason,
            },
          },
        }).catch((e) => {
          e
          this.$router.push('/login')
        })

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.$router.push('/login')
          this.isLoading = false
          return
        }
        if (m_status == 'suspend') {
          this.$router.push('home')
        }
        if (m_status == 'ok') {
          this.rec.status_n = 1
        }
        if (m_status == 'ng') {
          this.$router.push('/recommends/male?status=liked')
        }
      },
      async submitReply(status) {
        this.isLoading = true
        const result = await axios({
          method: 'POST',
          url: '/api/recommend/submit_reply',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            recommend: {
              id: this.rec.id,
              status: status,
            },
          },
        }).catch((e) => {
          e
          this.$router.push('/login')
        })

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.$router.push('/login')
          this.isLoading = false
          return
        }
        this.isLoading = false
        if (status == 'ng') {
          this.$router.push('/home')
        }
      },

      async getAccountData() {
        this.isLoading = true
        const result = await axios({
          method: 'POST',
          url: '/api/profile/female',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            user: {
              uid: this.uid,
              partner_id: this.partner_id,
            },
          },
        }).catch((e) => {
          e
          this.$router.push('/login')
        })

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.$router.push('/login')
          this.isLoading = false
          return
        }
        this.isLoading = false
        this.profile = result.data.profile
        this.my_profile = result.data.my_profile
        this.rec = result.data.recommend
        this.data_all = result.data
        if (this.rec.status_n == 3) {
          this.$router.push('/home')
        }
        if (this.rec.is_choosable) {
          if (this.rec.status_n == 1) {
            this.showReply = true
          }
        }
      },
    },
  }
</script>
