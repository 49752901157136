<template>
  <div class="chat-view">
    <v-app-bar app style="top: 0">
      <v-toolbar-items>
        <v-btn icon @click="$router.push('/recommends/male?status=matching')" v-if="this.sex == 1">
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
        <v-btn icon @click="$router.push('/recommends?status=matching')" v-else>
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="username" v-if="this.sex == 1">{{ female.name }}</div>
      <div class="username" v-else>{{ male.name }}</div>
      <v-spacer></v-spacer>

      <v-toolbar-items>
        <router-link :to="{ name: 'ProfileFemale', query: { id: female.id } }" v-if="this.sex == 1">
          <div style="height: 100%; width: 80px">
            <img
              :src="female.profile1"
              style="height: 100%; padding: 3px"
              class="img-fluid rounded-circle"
              onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
            />
          </div>
        </router-link>
        <router-link :to="{ name: 'Profile', query: { id: male.id } }" v-if="this.sex == 2">
          <div style="height: 100%; width: 80px">
            <img
              :src="male.profile1"
              style="height: 100%; padding: 3px"
              class="img-fluid rounded-circle"
              onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';"
            />
          </div>
        </router-link>
      </v-toolbar-items>
    </v-app-bar>
    <div class="chat-bubbles" ref="chatbox" style="padding-top: 50px">
      <div v-for="(message, index) in messages" :key="index">
        <div :class="['chat-bubble', message.user_id === user ? 'user' : 'other']">
          <template v-if="message.user_id === user">
            <span class="timestamp" style="font-size: 0.5rem; font-weight: 900">{{ modifiDate(message.created_at) }}</span>
            <chat-bubble :message="message.content" :isUser="message.user_id === user" />
          </template>
          <template v-else>
            <chat-bubble :message="message.content" :isUser="message.user_id === user" />
            <span class="timestamp" style="font-size: 0.5rem; font-weight: 900">{{ modifiDate(message.created_at) }}</span>
          </template>
        </div>
      </div>
    </div>
    <div class="input-container" fixed-bottom>
      <v-textarea rows="1" auto-grow v-model="inputMessage" placeholder="メッセージを入力" outlined background="white" />
      <v-btn icon @click="speak" class="sendIcon" primary>
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import ChatBubble from '@/components/ChatBubble.vue'
  import ActionCable from 'actioncable'
  import axios from '../../util/axios'
  const cable = ActionCable.createConsumer(process.env.VUE_APP_WEBSOCKET_URL)
  export default {
    name: 'ChatView',
    components: {
      ChatBubble,
    },
    data() {
      return {
        user: null,
        sex: null,
        uid: null,
        uuid: null,
        client: null,
        access_token: null,
        expiry: null,
        token_type: null,
        messageChannel: null,
        inputMessage: '',
        partner_user_id: '',
        cable: null,
        account: null,
        male: {
          id: null,
          name: null,
          profile1: null,
        },
        female: {
          id: null,
          name: null,
          profile1: null,
        },

        update_read_response: 'suspend',
        messages: [],
        messages_t: [],
      }
    },
    computed: {},
    watch: {
      messages: {
        handler() {
          this.$nextTick(() => {
            this.scrollToBottom() // 新しいメッセージが追加されたらスクロール
          })
        },
        deep: true,
      },
    },
    created() {
      // this.fetchMessages();
      // this.createSubscription();

      this.messageChannel = cable.subscriptions.create(
        { channel: 'MessageChannel', rec: this.$route.query.uuid },
        {
          received: (data) => {
            let is_user = false
            if (data.user_id == this.user) {
              is_user = true
            } else {
              is_user = false
            }
            is_user

            if (data.message != null || data.message != undefined || data.message != '') {
              this.messages.push({
                content: data.message.message,
                user_id: data.message.user_id,
                created_at: data.message.created_at,
              })
            }
            // this.mesages_t.push(data)
            this.$nextTick(() => {
              this.scrollToBottom()
            })
          },
        },
      )
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.expiry = localStorage.getItem('expiry')
      this.token_type = localStorage.getItem('token_type')
      this.uuid = this.$route.query.uuid
      this.getAccountData()
      this.sex = localStorage.getItem('sex')
      this.partner_user_id = this.$route.query.id
      this.submit_already_read()
    },
    methods: {
      modifiDate(dateTimeString) {
        // Dateオブジェクトを作成
        const dateObject = new Date(dateTimeString)

        // mm/dd hh:mmのフォーマットに変換する関数
        function formatDate(date) {
          const month = String(date.getMonth() + 1).padStart(2, '0') // 月を2桁で取得
          const day = String(date.getDate()).padStart(2, '0') // 日を2桁で取得
          const hours = String(date.getHours()).padStart(2, '0') // 時を2桁で取得
          const minutes = String(date.getMinutes()).padStart(2, '0') // 分を2桁で取得

          return `${month}/${day} ${hours}:${minutes}`
        }

        // 変換した日時の文字列を取得して返す
        return formatDate(dateObject)
      },
      async create_like(rev) {
        const result = await axios
          .post('/api/recommend_likes', {
            // const result = await axios.post("/api/recommend_likes", {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
            uid: this.uid,
            recommend_id: rev.id,
          })
          .catch((e) => {
            console.error(e)
          })
        rev.liked_count += 1
        rev.already_liked = true
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return
        }
      },

      async getAccountData() {
        const result = await axios
          .get('/api/message', {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
              'token-type': this.token_type,
              expiry: this.expiry,
            },
            params: {
              uuid: this.uuid,
            },
          })
          .catch((e) => {
            e
          })
        // this.account = result.data.email;
        this.male = result.data.male
        this.female = result.data.female
        this.user = result.data.user
        this.messages = result.data.messages
      },
      async submit_already_read() {
        const headers = {
          'Content-Type': 'application/json',
          uid: this.uid,
          'access-token': this.access_token,
          client: this.client,
        }

        const result = await axios
          .post(
            '/api/recommends/read',
            {},
            {
              headers: headers,
              params: {
                recommend: {
                  uuid: this.uuid,
                },
              },
            },
          )
          .catch((e) => {
            console.log(e)
          })
        this.update_read_response = result.data
      },

      createSubscription() {
        this.subscription = cable.subscriptions.create(
          { channel: 'MessageChannel' + this.partner_user_id },
          {
            received: (message) => {
              let is_user = false
              if (message.user_id == this.user) {
                is_user = true
              } else {
                is_user = false
              }
              if (message.message != null || message.message != undefined || message.message != '') {
                this.messages.push({
                  content: message.content,
                  isUser: is_user,
                  account: this.account,
                  created_at: message.created_at,
                })
              }
              // this.submit_already_read();
              this.$nextTick(() => {
                this.scrollToBottom()
              })
            },
          },
        )
      },
      sendMessage() {
        if (this.inputMessage.trim() !== '') {
          this.messages.push({ content: this.inputMessage, isUser: true })
          this.inputMessage = ''
          this.$nextTick(() => {
            this.scrollToBottom()
          })
        }
      },
      speak() {
        // 正規表現パターン
        const urlPattern = /(https?:\/\/\S+)|(www\.\S+\.\S+)/gi
        // テキストからURLを抽出する
        if (urlPattern.test(this.inputMessage)) {
          this.inputMessage = ''
          return
        }

        if (this.inputMessage.trim() === '') return
        this.messageChannel.perform('speak', {
          message: this.inputMessage,
          account: this.user,
          rec: this.uuid,
        })
        this.inputMessage = ''
      },
      scrollToBottom() {
        const chatbox = this.$refs.chatbox
        chatbox.scrollTop = chatbox.scrollHeight
      },
    },
  }
</script>

<style scoped>
  .chat-view {
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: calc(100vh - 50px); /* チャットビュー全体の高さを画面いっぱいに拡張 */
  }

  .chat-bubble-wrapper {
    flex-shrink: 0; /* チャットバブルが画面いっぱいに広がるのを防止 */
    /* ... */
  }
  .chat-bubbles {
    flex: 1; /* チャットバブルを画面いっぱいに拡張 */
    overflow-y: auto; /* チャットバブルが画面いっぱいに広がるのを防止 */
  }
  .user {
    display: flex;
    justify-content: flex-end;
  }
  .other {
    display: flex;
    justify-content: flex-start;
  }

  .input-container {
    margin-top: auto; /* 入力ボックスを下部に配置 */

    display: flex;
    align-items: start; /* 垂直方向の中央揃え */
  }
  .sendIcon {
    padding-top: 20px;
  }

  .v-toolbar__content {
    top: 0 !important;
    display: fix;
  }
  .page-wrapper {
    overflow-y: hidden;
  }
  .username {
    font-size: 1.3rem;
    font-weight: 900;
    color: #707070;
    margin-bottom: 4px;
  }
  .timestamp {
    padding-right: 10px;
    padding-left: 10px;
    color: gray;
  }
</style>
