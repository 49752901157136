<template>
  <div class="age_confirmation container content-wrapper" style="padding-top: 10px">
    <v-app>
      <div class="text-center">
        <v-dialog v-model="dialog" overlay-color="#ffffff" max-width="500">
          <v-card style="text-align: center">
            <v-card-title class="text-h5">
              <p style="font-size: 1.2rem; line-height: 3rem; margin-bottom: 0">
                <span style="color: #f85b8c; font-weight: 900; border-bottom: solid 1px #f85b8c">『発行元』『生年月日』</span>
                がわかるように提出して下さい。
              </p>
            </v-card-title>
            <img src="@/assets/age_conf/免許証.png" class="age-conf-img" />
            <img src="@/assets/age_conf/マイナンバー.png" class="age-conf-img" />
            <img src="@/assets/age_conf/保険証.png" class="age-conf-img" />
            <img src="@/assets/age_conf/パスポート.png" class="age-conf-img" />

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text scrollable @click="dialog = false">上記内容を確認しました</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="white-card" style="margin-bottom: 5px">
        <h1 style="margin-bottom: 0; font-size: 1.23rem; color: #4b4b4b">身分証確認</h1>
      </div>
      <h2 ref="test" style="margin-top:8px; !important">身分証を提出</h2>
      <div class="white-card">
        <div class="container">
          <h3>
            <span>
              <Icon name="exclamation-triangle" style="color: #f85b8c; margin: 3px; margin-right: 8px" scale="1.3" />
              注意
              <Icon name="exclamation-triangle" style="color: #f85b8c; margin: 3px; margin-left: 8px" scale="1.3" />
            </span>
          </h3>
          <hr />
          <div class="container" style="text-align: left">
            <p style="font-size: 1rem; line-height: 2rem; margin-bottom: 0">
              <span style="color: #f85b8c; font-weight: 900; border-bottom: solid 1px #f85b8c" @click="dialog = true">『発行元』『生年月日』</span>
              がわかるように提出して下さい。
              <br />
              ※それ以外はモザイク等の修正をかけて下さい。
            </p>
          </div>
          <hr />
          <div class="container" style="text-align: left">
            <p style="font-size: 1rem; line-height: 2rem; font-weight: 900; margin-bottom: 0">
              <span style="color: #f85b8c">高校生利用不可</span>
            </p>
          </div>
        </div>
      </div>

      <div class="white-card" style="margin-top: 5px">
        <div class="container">
          <h3><span>提出可能な身分証</span></h3>
          <hr />
          <div class="container" style="text-align: left">
            <ul style="margin-left: 10px; color: #f85b8c; font-weight: 900">
              <li>運転免許証</li>
              <li>保険証</li>
              <li>パスポート</li>
              <li>マイナンバーカード</li>
            </ul>
          </div>

          <div class="age-conf-img-wrapper" v-if="false">
            <div class="age-conf-card correct">
              <p class="msg correct-msg">正しい例</p>
              <img src="@/assets/age_conf/01.png" class="age-conf-img" />
              <p>身分証の全体が画面全体にはっきり写るよう撮影してください</p>
            </div>

            <div class="age-conf-card error">
              <p class="msg error-msg">NG例 はみだしている</p>
              <img src="@/assets/age_conf/02.png" class="age-conf-img" />
              <p>身分証の全体が写るように撮影してください</p>
            </div>

            <div class="age-conf-card error">
              <p class="msg error-msg">NG例 反射している</p>
              <img src="@/assets/age_conf/03.png" class="age-conf-img" />
              <p>身分証が光で反射しないように撮影してください</p>
            </div>

            <div class="age-conf-card error">
              <p class="msg error-msg">NG例 ぼやけている</p>
              <img src="@/assets/age_conf/04.png" class="age-conf-img" />
              <p>身分証がぼやけないように撮影してください</p>
            </div>

            <div class="age-conf-card error">
              <p class="msg error-msg">NG例 加工している</p>
              <img src="@/assets/age_conf/05.png" class="age-conf-img" />
              <p>加工しているものは年齢確認書類として認められません</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <input type="file" @change="onImageUploaded" />
        <button v-show="file_check" class="btn-comp login-btn" @click="post()" :disabled="disabled_flag">
          {{ btn_text }}
        </button>
      </div>
    </v-app>
  </div>
</template>
<style scoped>
  h3 {
    font-size: 1.4rem;
    font-weight: 900;
    padding: 6px;
  }
  hr {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px dashed #cccccc;
  }
  .white-card {
    filter: drop-shadow(8px 8px 10px #bbbbbb);
  }
  .msg {
    padding-top: 15px;
    font-weight: 800;
  }
  .age-conf-card {
    border: 1px solid gray;
    border-radius: 3px;
    margin: 10px;
  }
  .correct {
    background: #cdffc3;
  }
  .error {
    background: #ffced3;
  }
  .age-conf-img {
    max-width: 200px;
    border: 1px solid gray;
    border-radius: 3px;
    margin-bottom: 10px;
    margin: 2px;
  }
  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }
  .v-dialog__container {
    display: unset;
    pointer-events: none;
  }
</style>

<script>
  import Icon from 'vue-awesome/components/Icon'
  import axios from '../../util/axios'

  export default {
    name: 'age_confirmation',
    data() {
      return {
        id: '',
        uid: '',
        client: '',
        access_token: '',
        email: '',
        twitterid: '',
        info: '',
        info_ts: '',
        payment_conf: '',
        age_conf: '',
        data_all: '',
        rank: '-',
        rc: '',
        disabled_flag: false,
        file_check: false,
        matching_count: 0,
        recommend_count: 0,
        btn_text: '書類を提出する',
        post_url: '',
        sub_file: null,
        file: null,
        param_data: null,
        sex: '',
        add_html: null,
        dialog: true,
      }
    },
    methods: {
      onImageUploaded(e) {
        // event(=e)から画像データを取得する
        this.post_file = e.target.files[0]
        this.file_check = true
      },
      redirectLogin() {
        //ページ遷移
        this.$router.push('/login')
      },
      post() {
        this.disabled_flag = true
        this.btn_text = 'アップロード中...'
        // const post_file=this.file[0];
        const fr = new FileReader()
        const params = {
          filename: this.id,
          imageformat: 'PNG',
          file: '',
        }
        // const url = this.post_url
        // var html =""
        const _this = this
        fr.onload = async function (e) {
          params.file = e.target.result.replace(/^.*,/, '')

          const result = await axios
            .post('/api/age_conf/submit', {
              headers: {
                uid: _this.uid,
                'access-token': _this.access_token,
                client: _this.client,
              },
              params,
            })
            .catch((e) => {
              console.error(e)
            })
          if (result.data.success == true) {
            _this.$router.push({ name: 'Home', query: { age_conf_flag: 1 } })
          } else {
            _this.btn_text = 'しばらく後に再度提出してください'
          }
        }

        fr.readAsDataURL(this.post_file)
      },

      async getAccountData() {
        const result = await axios
          .get('/api/account', {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
          })
          .catch((e) => {
            e
          })

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin()
          return
        }
        if (!result.data.email) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin()
          return
        }
        this.id = result.data.id
        this.email = result.data.email
        this.twitterid = result.data.twitterid
        this.twitter_name = result.data.twitter_name
        this.info = result.data.notification[0].info
        this.info_ts = result.data.notification[0].created_at

        this.payment_conf = result.data.payment_conf
        this.age_conf = result.data.age_conf
        this.data_all = result.data
        this.sex = result.data.sex
        localStorage.setItem('sex', this.sex)
        this.rank = result.data.rank
        this.matching_count = result.data.matching_count
        this.recommend_count = result.data.recommend_count
        this.rc = result.data.rc

        if (this.matching_count == null) {
          this.matching_count = 0
        }

        if (this.recommend_count == null) {
          this.recommend_count = 0
        }

        this.isLoading = false
      },
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.sex = localStorage.getItem('sex')
      if (this.sex == 1) {
        // dev env
        // this.post_url =
        this.post_url = process.env.VUE_APP_MALE_AGE_CONF
      } else {
        // this.post_url =
        this.post_url = process.env.VUE_APP_FEMALE_AGE_CONF
      }
      this.getAccountData()
    },
    components: {
      Icon,
    },
  }
</script>
