<template>
  <div class="tutorial_container">
    <div class="container">
      <p class="headerp">
        あなたの性癖から
        <br />
        AIが毎日レコメンド
      </p>

      <div class="white-card index-wrapper">
        <div style="display: flex; flex-direction: row; margin-left: 10px">
          <p class="subtitle-index">
            <Icon name="comments" scale="1.8" style="color: #fc4ed8" />
            AIレコメンドについて
          </p>
        </div>
      </div>
      <div class="white-card index-wrapper">
        <div style="display: flex; flex-direction: row; margin-left: 10px">
          <p class="subtitle-index">
            <Icon name="comments" scale="1.8" style="color: #fc4ed8" />
            AIレコメンドの上限について
          </p>
        </div>
      </div>
      <div class="white-card index-wrapper">
        <div style="display: flex; flex-direction: row; margin-left: 10px">
          <p class="subtitle-index">
            <Icon name="comments" scale="1.8" style="color: #fc4ed8" />
            性癖ちゃんからのレコメンド
          </p>
        </div>
      </div>
      <div class="white-card index-wrapper">
        <div style="display: flex; flex-direction: row; margin-left: 10px">
          <p class="subtitle-index">
            <Icon name="comments" scale="1.8" style="color: #fc4ed8" />
            レコメンドボタンの選択について
          </p>
        </div>
      </div>
      <div class="subtitle-pink">
        <p>AIレコメンドについて</p>
      </div>
      <div class="pink-card">
        <div class="container">
          <p style="font-weight: 900; font-size: 20px; margin-top: 15px">
            <Icon name="exclamation-triangle" style="margin-bottom: 3px" />
            ご注意
          </p>
          <p style="font-size: 16px">
            下記の説明を必ず読んでから
            <br />
            「AIレコメンド」もしくは「性癖ちゃんからのレコメンド」のボタンを選んで押してくださいね！
          </p>
          <p style="font-size: 20px; font-weight: 900; text-decoration: underline">
            間違えてレコメンドボタンを押した場合の
            <br />
            レコメンドの取り消しはできません😭
          </p>
        </div>
        <div class="container">
          <div class="white-card container">
            <p style="text-align: left; font-size: 1rem">
              <span style="font-weight: 900">レコメンドされた男性とマッチンした場合：</span>
              返信の有無を入力後、再度レコメンドボタンが表示され「AIレコメンド」「性癖ちゃんからのレコメンド」を選択することが出来ます。
            </p>
            <p style="text-align: left; font-size: 1rem; margin-bottom: 0">
              <span style="font-weight: 900">レコメンドされた男性をノーマッチンした場合：</span>
              再度レコメンドボタンが表示され「AIレコメンド」「性癖ちゃんからのレコメンド」を選択することが出来ます。
            </p>
          </div>
        </div>
      </div>
      <div class="white-card">
        <div class="container">
          <p class="number">01</p>
          <p class="subtitle-white-card">自分の性癖にあったタイプを入力する</p>
          <p class="subtitle-white-card2">自分の性癖からマッチンしたいタイプの項目にチェックを入れます。</p>
          <img src="@/assets/tutorial/tutorial02.png" class="tutorial-img" />
          <p></p>
          <img src="@/assets/tutorial/arrow.png" class="tutorial-arrow-img" />
          <p class="number">02</p>
          <p class="subtitle-white-card">全ての項目を入力後に更新する</p>
          <p class="subtitle-white-card2">
            マッチンしたいタイプの項目を入力後に「更新する」を選択します。
            <br />
            ここで入力した項目からあなたに合った性癖の男性をAIがレコメンドします。
          </p>
          <img src="@/assets/tutorial/tutorial03.png" class="tutorial-img" />
          <p></p>
          <img src="@/assets/tutorial/arrow.png" class="tutorial-arrow-img" />
          <p class="number">03</p>
          <p class="subtitle-white-card">AIレコメンドからのレコメンドを見る</p>
          <p class="subtitle-white-card2">
            ホーム画面の「AIレコメンド」を選択すると条件に合った男性がレコメンドされます。
            <br />
            ※1日3件まで(翌朝3時にリセットされます)
          </p>
          <img src="@/assets/tutorial/tutorial04.png" class="tutorial-img" />
          <p></p>
          <img src="@/assets/tutorial/arrow.png" class="tutorial-arrow-img" />
          <p class="number">04</p>
          <p class="subtitle-white-card">レコメンドされた男性を見る</p>
          <p class="subtitle-white-card2">
            「AIレコメンド」が1人ずつ、最大3人まで毎日レコメンドをします。プロフィールを見て、いいなと思ったら「男性に連絡する」、微妙だなと思ったら「男性に連絡しない」を選択してください。
          </p>
          <img src="@/assets/tutorial/tutorial05.png" class="tutorial-img" />
          <p></p>
          <p class="failure-box">※AIレコメンド開始に伴い「保留」選択は廃止となりました。</p>

          <img src="@/assets/tutorial/arrow.png" class="tutorial-arrow-img" />
          <p class="subtitle-white-card" style="color: #ee12a7">マッチンした場合</p>
          <p class="subtitle-white-card2">
            「男性に連絡する」を選択して、マッチンした場合は男性からの返信の有無を選択すると、次のレコメンドがもらえます。
          </p>
          <img src="@/assets/tutorial/tutorial06.png" class="tutorial-img" />
          <p></p>
          <p></p>
          <p></p>
          <p class="subtitle-white-card" style="color: #7b43b2">マッチンしなかった場合</p>
          <p class="subtitle-white-card2">
            「男性に連絡しない」を選択した場合はホーム画面より「AIレコメンド」を選択すると次のレコメンドがもらえます。
            <br />
            ※1日3件まで(翌朝3時にリセットされます)
          </p>
        </div>
      </div>
      <div class="subtitle-pink">
        <p>AIレコメンドの上限について</p>
      </div>
      <div class="white-card">
        <div class="container">
          <p class="subtitle-white-card">AIレコメンドは１日３件まで</p>
          <p class="subtitle-white-card2">
            1日3件、AIがあなたに合った男性をレコメンドします。1日の上限に達した場合は、翌朝3時にカウントはリセットされます。
          </p>
          <img src="@/assets/tutorial/tutorial07.png" class="tutorial-img" />
          <p></p>
          <div class="pink-card" style="background: #fee3f8; margin-top: 15px">
            <p class="subtitle-white-card" style="margin-top: 30px">
              AIレコメンドから
              <br />
              レコメンドが受け取れない？
            </p>
            <div class="container">
              <p class="subtitle-white-card2">AIレコメンドからレコメンドが受け取れない場合はここをチェックしてください。</p>
              <div class="container">
                <div class="report-box">
                  <Icon name="exclamation-triangle" class="report-icon" />
                  <p class="report-item">1日の上限に達している(1日3件まで)</p>
                </div>
                <div class="report-box">
                  <Icon name="exclamation-triangle" class="report-icon" />
                  <p class="report-item">レコメンドされた男性に対してマッチン、ノーマッチン未選択</p>
                </div>
                <div class="report-box">
                  <Icon name="exclamation-triangle" class="report-icon" />
                  <p class="report-item">男性からの返事の有無未選択</p>
                </div>
                <div class="report-box">
                  <Icon name="exclamation-triangle" class="report-icon" />
                  <p class="report-item">"性癖ちゃんからのレコメンド"を選択している</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="subtitle-pink">
        <p>性癖ちゃんからのレコメンド</p>
      </div>
      <div class="pink-card">
        <div class="container">
          <p style="font-weight: 900; font-size: 20px; margin-top: 15px">
            <Icon name="exclamation-triangle" style="margin-bottom: 3px" />
            ご注意
          </p>
          <p style="font-size: 16px">
            下記の説明を必ず読んでから
            <br />
            「AIレコメンド」もしくは「性癖ちゃんからのレコメンド」のボタンを選んで押してくださいね！
          </p>
          <p style="font-size: 20px; font-weight: 900; text-decoration: underline">
            間違えてレコメンドボタンを押した場合の
            <br />
            レコメンドの取り消しはできません😭
          </p>
        </div>
      </div>
      <div class="white-card">
        <div class="container">
          <p class="subtitle-white-card">性癖ちゃんがレコメンドします</p>
          <p class="subtitle-white-card2">
            性癖ちゃんがTwitterDMにてレコメンドを送ります！DMが届いたらマイページから男性のプロフィールを確認してマッチン・ノーマッチンを選択してください。
          </p>
          <img src="@/assets/tutorial/tutorial08.png" class="tutorial-img" />
        </div>
      </div>
      <Icon />
      <div class="subtitle-pink">
        <p>レコメンドボタンの選択について</p>
      </div>
      <div class="white-card">
        <div class="container">
          <p class="subtitle-white-card">毎回お好きなレコメンドボタンを選択</p>
          <p class="subtitle-white-card2">
            「AIレコメンド」と「性癖ちゃんからのレコメンド」は毎回お好きなレコメンドボタンを選択して、レコメンドを受け取ることができるのでご安心くださいね！
          </p>
        </div>
      </div>

      <div class="white-card" style="border: 3px solid #f513ac">
        <p class="check-head">
          <Icon name="exclamation-triangle" style="color: #fc4ed8; margin-right: 5px; margin-bottom: 3px" />
          注意
          <Icon name="exclamation-triangle" style="color: #fc4ed8; margin-left: 5px; margin-bottom: 3px" />
        </p>

        <div class="container">
          <label style="text-align: left" class="wradd">
            <input type="checkbox" name="confirmation1" v-model="confirmation1" style="max-width: 20px" />
            <p style="margin-bottom: 0" class="check-text">AIレコメンドと性癖ちゃんからのレコメンドの違いを確認しました！</p>
          </label>
          <p></p>

          <label style="text-align: left" class="wradd">
            <input type="checkbox" name="confirmation1" v-model="confirmation2" style="max-width: 20px" />
            <p style="margin-bottom: 0" class="check-text">
              レコメンドボタンを間違えて押した場合、受け取ったレコメンドの取り消しが出来ないことを確認しました！
            </p>
          </label>

          <p class="subtitle-white-card">
            以上、2点を確認して
            <br />
            チェックボタンを押してください
          </p>
          <button
            @click="submitTutorialCheck()"
            :disabled="!confirmation1 || !confirmation2"
            type="submit"
            class="signin-btn btn-comp"
            v-show="confirmation1 && confirmation2"
          >
            ホームへ
          </button>
          <button :disabled="true" type="submit" class="disabled-btn btn-comp" v-show="!(confirmation1 && confirmation2)">ホームへ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@900&display=swap');
  .wradd {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  .signin-btn {
    background: rgb(97, 34, 219);
    background: linear-gradient(90deg, rgba(97, 34, 219, 1) 0%, rgba(91, 103, 229, 1) 100%);
    margin-top: 3px;
  }
  .disabled-btn {
    background: #adacad;

    margin-top: 3px;
  }
  .check-head {
    color: #7b7693;
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    height: auto;
    line-height: 1.4;
    padding: 0px 10px 0px 10px;
    text-align: center;
    width: auto;
    max-width: 100%;
    justify-content: center;
  }
  .check-text {
    color: #fa3abb;
    font-family: Lato;
    font-size: 16px;
    font-weight: 900;
    line-height: 1.4;
    text-align: left;
    justify-content: flex-start;
    margin-left: 15px;
  }
  .report-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .report-icon {
    vertical-align: middle;
    color: #7652e6;
    font-size: 14px;
    padding: 0px 0px 0px 0px;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    max-width: 20px;
  }
  .report-item {
    color: #7652e6;
    flex: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.6;
    margin: 0px 0px 0px 5px;
    padding: 0px 0px 0px 0px;
    text-align: left;
    max-width: calc(100% - 5px);
    justify-content: flex-start;
  }
  .tutorial-img {
    width: 400px;
    max-width: 100%;
  }
  .tutorial-arrow-img {
    width: 200px;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .number {
    width: 50px;
    height: 50px;
    background-color: #f24fd0;
    color: white;
    font-weight: 900;
    font-size: 16px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    line-height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .failure-box {
    margin-top: 20px;
    padding: 10px;
    color: white;
    background: #686181;
    text-align: left;
  }
  .subtitle-pink {
    background: #ff72e0;
    color: #ffffff;
    flex: none;
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.4;
    margin: 0px 0px 25px 0px;
    padding: 10px;
    text-align: center;
    margin-top: 45px;
  }
  .subtitle-index {
    color: #6e6787;
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.4;
    text-align: left;
    margin-bottom: 0;
    padding: 5px;
    margin-left: 5px;
  }
  .index-wrapper {
    border-radius: 30px;
    border: 1px solid grey;
    vertical-align: middle;
    margin-top: 13px !important;
  }
  .container {
    max-width: 768px;
  }
  .subtitle-pink p {
    color: white;
    font-size: 16px;
    margin-bottom: 0;
  }
  .subtitle-white-card {
    color: #7b7693;
    font-size: 16px;
    font-weight: 900;
    border-bottom: 1px dashed #d7d7d7;
    padding-bottom: 15px;
  }
  .subtitle-white-card2 {
    color: #7b7693;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    height: auto;
    line-height: 1.4;
    margin: 20px 0px 20px 0px;
    text-align: center;
    width: auto;
    max-width: 100%;
    justify-content: center;
    text-align: left;
  }
  .pink-card {
    border-radius: 5px;
    background: #feaeee;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .white-card {
    margin-top: 25px;
  }
  .headerp {
    font-size: 36px;
    line-height: 1.4;
    /*margin: 20px 0px 40px 0px;*/
    /*padding: 0px 20px 0px;*/
    max-width: 100%;
    color: #ffbff1;
    font-family: 'M PLUS Rounded 1c';
    font-feature-settings: 'palt' 1;

    font-weight: 900;
    height: auto;
    line-height: 1.4;
    margin: 0px 0px 40px 0px;
    padding: 30px 0px 0px;
    text-align: center;
    width: auto;
    max-width: 100%;
    justify-content: center;
  }
  .tutorial_container {
    background: #fee3f8;
    min-height: 100vh;
  }
  .alert {
    color: red;
    text-align: left;
    margin: auto;
    margin-left: 0;
    top: 0;
    bottom: 0;
    padding-left: 0px;
  }
  .alert-icon {
    vertical-align: middle;
    display: flex;
    padding: 15px;
    margin: 0;
  }
  .alert-wrapper {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }
  .amount {
    font-weight: 900;
    font-size: 2.5rem;
    background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
    -webkit-background-clip: text;
    color: transparent;
  }
  .amount-value {
    font-size: 3rem;
  }
</style>
<script>
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'
  import axios from '../../util/axios'
  export default {
    name: 'Tutorial',
    components: {
      Icon,
    },
    data() {
      return {
        uid: null,
        client: null,
        access_token: null,
        confirmation1: false,
        confirmation2: false,
      }
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      if (this.uid === null) {
        this.$router.push('/login')
      }
      this.$nextTick(function () {
        window.scrollTo(top, 0)
      })
    },

    methods: {
      async submitTutorialCheck() {
        this.isLoading = true
        const result = await axios({
          method: 'POST',
          url: '/api/tutorial_check',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e
          this.$router.push('/home')
        })

        if (result) {
          this.$router.push('home')
          return
        }
        this.isLoading = false
      },
    },
  }
</script>
