import Vue from 'vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import index from '../views/Index.vue'
import login from '../views/Login.vue'
import signup from '../views/SignUp.vue'
import forget_password from '../views/ForgetPassword.vue'
import password_reset from '../views/PasswordReset.vue'
import home from '../views/Home.vue'
import form from '../views/Form.vue'
import partialform from '../views/PartialForm.vue'
import femaleform from '../views/FemaleForm.vue'
import paymentinformation from '../views/Paymentinformation.vue'
import preview from '../views/Preview.vue'
import profile from '../views/Profile.vue'
import profile_female from '../views/ProfileFemale.vue'
import review from '../views/Review.vue'
import reviewform from '../views/ReviewForm.vue'
import reviewindex from '../views/ReviewIndex.vue'
import myreviewindex from '../views/MyReviewIndex.vue'
import topics from '../views/Topics.vue'
import topic from '../views/Topic.vue'
import age_confirmation from '../views/AgeConfirmation.vue'
import recommends from '../views/Recommends.vue'
import male_recommends from '../views/MaleRecommends.vue'
import recommend from '../views/Recommend.vue'
import noticeindex from '../views/NoticeIndex.vue'
import notice from '../views/Notice.vue'
import user_confirmation from '../views/UserConfirmation.vue'
import after_sign_up from '../views/AfterSignUp.vue'
import change_card from '../views/ChangeCard.vue'
import payment_select from '../views/PaymentSelect.vue'
import payment_select_campaign from '../views/PaymentSelectCampaign.vue'
import payment_select_campaign2 from '../views/PaymentSelectCampaign2.vue'
import payment_cancellation from '../views/PaymentCancellation.vue'
import rankrequest from '../views/Rankrequest.vue'
import qa from '../views/QA.vue'
import conditions_setting from '../views/ConditionsSetting.vue'
import tutorial from '../views/Tutorial.vue'
import maletutorial from '../views/MaleTutorial.vue'
import shop from '../views/Shop.vue'
import chat from '../views/Chat.vue'
import timeline from '../views/TimeLine.vue'
import subprofile from '../views/SubProfile.vue'
import admin_message from '../views/AdminMessage.vue'
import questionaire from '../views/Questionaire.vue'
Vue.use(VueHead)
Vue.use(VueRouter)

const routes = [
  {
    path: '/payment_cancellation',
    name: 'PaymentCancellation',
    component: payment_cancellation,
    meta: { title: '継続課金解約手続き', desc: '' },
  },
  {
    path: '/conditionsform',
    name: 'ConditionsSetting',
    component: conditions_setting,
    meta: { title: '条件設定', desc: '' },
  },
  {
    path: '/change_card',
    name: 'ChangeCard',
    component: change_card,
    meta: { title: 'カード情報の変更', desc: '' },
  },
  {
    path: '/',
    name: 'Index',
    component: index,
    meta: { title: '性癖マッチン', desc: '' },
  },
  {
    path: '/login', //sign_in
    name: 'Login',
    component: login,
    meta: { title: 'ログイン', desc: '' },
  },
  {
    path: '/signup', //sign_up
    name: 'SignUp',
    component: signup,
    meta: { title: '新規登録', desc: '' },
  },
  {
    path: '/age_confirmation', //sign_up
    name: 'AgeConfirmation',
    component: age_confirmation,
    meta: { title: '年齢確認書類提出', desc: '' },
  },
  {
    path: '/user_confirmation',
    name: 'Confirmation',
    component: user_confirmation,
    meta: { title: 'ユーザー認証', desc: '' },
  },
  {
    path: '/after_sign_up',
    name: 'AfterSignUp',
    component: after_sign_up,
    meta: { title: 'ユーザー認証', desc: '' },
  },
  {
    path: '/forget_password',
    name: 'ForgetPassword',
    component: forget_password,
    meta: { title: 'パスワード再設定', desc: '' },
  },
  {
    path: '/password_reset',
    name: 'PasswordReset',
    component: password_reset,
    meta: { title: 'パスワード再設定', desc: '' },
  },
  {
    path: '/home', //mypage
    name: 'Home',
    component: home,
    meta: { title: 'マイページ', desc: '' },
  },
  {
    path: '/form', //mypage
    name: 'Form',
    component: form,
    meta: { title: 'プロフィール', desc: '' },
  },
  {
    path: '/partialform', //mypage
    name: 'PartialForm',
    component: partialform,
    meta: { title: 'プロフィール', desc: '' },
  },
  {
    path: '/reviewform', //mypage
    name: 'ReviewForm',
    component: reviewform,
    meta: { title: 'レビュー', desc: '' },
  },
  {
    path: '/reviewindex', //mypage
    name: 'ReviewIndex',
    component: reviewindex,
    meta: { title: '投稿したレビュー一覧', desc: '' },
  },
  {
    path: '/myreviewindex', //mypage
    name: 'MyReviewIndex',
    component: myreviewindex,
    meta: { title: '投稿したレビュー一覧', desc: '' },
  },
  {
    path: '/femaleform', //mypage
    name: 'FemaleForm',
    component: femaleform,
    meta: { title: 'プロフィール', desc: '' },
  },
  //profile
  {
    path: '/profile', //mypage
    name: 'Profile',
    component: profile,
    meta: { title: 'プロフィール', desc: '' },
  },
  {
    path: '/profile/female', //mypage
    name: 'ProfileFemale',
    component: profile_female,
    meta: { title: 'プロフィール', desc: '' },
  },
  //noticeindex
  {
    path: '/notice/index', //mypage
    name: 'NoticeIndex',
    component: noticeindex,
    meta: { title: 'お知らせ一覧', desc: '' },
  },
  //notice
  {
    path: '/notice/:id', //mypage
    name: 'Notice',
    component: notice,
    meta: { title: 'お知らせ', desc: '' },
  },
  //preview
  {
    path: '/preview', //mypage
    name: 'Preview',
    component: preview,
    meta: { title: 'プレビュー', desc: '' },
  },
  //rank_request
  {
    path: '/rankrequest',
    name: 'Rankrequest',
    component: rankrequest,
    meta: { title: 'ランク付け依頼', desc: '' },
  },
  //payment_information
  {
    path: '/payment_information', //mypage
    name: 'Paymentinformation',
    component: paymentinformation,
    meta: { title: '決済情報', desc: '' },
  },
  //review
  {
    path: '/review', //mypage
    name: 'Review',
    component: review,
    meta: { title: 'みんなのレビュー', desc: '' },
  },
  //topics
  {
    path: '/topics', //mypage
    name: 'Topics',
    component: topics,
    meta: { title: '掲示板', desc: '' },
  },
  //topic #id
  {
    path: '/topic', //mypage
    name: 'Topic',
    component: topic,
    meta: { title: '掲示板', desc: '' },
  },
  //recommends #id
  {
    path: '/recommends', //mypage
    name: 'Recommends',
    component: recommends,
    meta: { title: 'レコメンド一覧', desc: '' },
  },
  {
    path: '/recommends/male',
    name: 'MaleRecommends',
    component: male_recommends,
    meta: { title: 'レコメンド一覧', desc: '' },
  },
  {
    path: '/recommend', //mypage
    name: 'Recommend',
    component: recommend,
    meta: { title: 'レコメンド', desc: '' },
  },
  {
    path: '/payment_select',
    name: 'PaymentSelect',
    component: payment_select,
    meta: { title: 'プラン選択', desc: '' },
  },
  {
    path: '/payment_select_campaign',
    name: 'PaymentSelectCampaign',
    component: payment_select_campaign,
    meta: { title: 'プラン選択', desc: '' },
  },
  {
    path: '/payment_select_campaign/bxudfuxc7ebwwq',
    name: 'PaymentSelectCampaign2',
    component: payment_select_campaign2,
    meta: { title: 'プラン選択', desc: '' },
  },
  // QA
  {
    path: '/qa', //mypage
    name: 'QA',
    component: qa,
    meta: { title: 'QA', desc: '' },
  },

  {
    path: '/tutorial',
    name: 'Tutorial',
    component: tutorial,
    meta: { title: 'チュートリアル', desc: '' },
  },
  {
    path: '/maletutorial',
    name: 'MaleTutorial',
    component: maletutorial,
    meta: { title: 'チュートリアル', desc: '' },
  },
  {
    path: '/chat',
    name: 'chat',
    component: chat,
    meta: { title: 'チャット', desc: '' },
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: timeline,
    meta: { title: 'タイムライン', desc: '' },
  },
  {
    path: '/subprofile',
    name: 'subprofile',
    component: subprofile,
    meta: { title: 'プロフィール', desc: '' },
  },
  {
    path: '/shop',
    name: 'shop',
    component: shop,
    meta: { title: '購入', desc: '' },
  },
  {
    path: '/hona',
    name: 'hona',
    component: admin_message,
    meta: { title: '性癖ちゃん', desc: '' },
  },
  {
    path: '/questionaire',
    name: 'questionaire',
    component: questionaire,
    meta: { title: 'アンケート', desc: '' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        top: 0,
      }
    } else {
      return {
        top: 0,
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.path === '/chat') {
    // 特定のルートではフッターを非表示にする
    // フッターを表示するための変数をfalseに設定
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = false
  } else if (to.path === '/login') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/signup') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/age_confirmation') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/payment_information') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/tutorial') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/forget_password') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/hona') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = false
  } else if (to.path === '/maletutorial') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/form') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/payment_select') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/femaleform') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/payment_select_campaign/bxudfuxc7ebwwq') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path === '/payment_select_campaign') {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = true
  } else if (to.path.match(/^\/notice\/\d+$/)) {
    Vue.prototype.$showFooter = false
    Vue.prototype.$showHeader = false
  } else {
    // その他のルートではフッターを表示する
    // フッターを表示するための変数をtrueに設定
    Vue.prototype.$showFooter = true
    Vue.prototype.$showHeader = true
  }
  next()
})

export default router
