<template>
  <div>
    <div class="icon-center">
      <div class="bg-loading"></div>
      <v-progress-circular v-if="isLoading" color="#f8608d" indeterminate></v-progress-circular>
      <v-icon v-else class="delete-icon" color="#f8608d" @click="toogleDialog">
        mdi-delete
      </v-icon>
    </div>

    <v-dialog v-model="isDialogVisible" max-width="300">
      <v-card>
        <v-card-title class="text-h6">削除確認</v-card-title>
        <v-card-text>
          削除してもよろしいですか？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small rounded depressed color="#f8608d" class="text-white" @click="confirmDelete">
            はい
          </v-btn>
          <v-btn small rounded depressed @click="toogleDialog">
            いいえ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'IconDelete',
    data() {
      return {
        isLoading: false,
        isDialogVisible: false
      }
    },
    methods: {
      toogleDialog() {
        this.isDialogVisible = !this.isDialogVisible
      },
      async confirmDelete() {
        this.toogleDialog()
        this.isLoading = true
        this.$emit('delete')
      }
    }
  }
</script>

<style scoped>
  .icon-center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
  }
  .bg-loading {
    background-color: #eee;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
</style>
