<template>
  <v-app>
    <div class="login">
      <div class="container">
        <Icon name="exclamation-triangle" scale="1" v-show="false" />
        <h2>男性の体型</h2>
        <div class="white-card container" style="text-align: left">
          <v-switch v-model="form.body_type0" label="痩せ型な男性" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.body_type1" label="普通体型な男性" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.body_type2" label="マッチョな男性" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.body_type3" label="ぽっちゃりな男性" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.body_type4" label="かなりぽっちゃりな男性" flat hide-details></v-switch>
        </div>

        <h2>男性のアピールポイント</h2>
        <div class="white-card container" style="text-align: left">
          <v-radio-group row v-model="form.appear_point" required>
            <v-radio label="おまかせ" value="おまかせ"></v-radio>
            <v-radio label="いちゃいちゃ" value="いちゃいちゃ"></v-radio>
            <v-radio label="キス" value="キス"></v-radio>
            <v-radio label="手マン" value="手マン"></v-radio>
            <v-radio label="クンニ" value="クンニ"></v-radio>
            <v-radio label="ピストン運動" value="ピストン運動"></v-radio>
            <v-radio label="拘束プレイ" value="拘束プレイ"></v-radio>
            <v-radio label="中イキ開発" value="中イキ開発"></v-radio>
            <v-radio label="脳イキ" value="催眠"></v-radio>
            <v-radio label="アソコの大きさ" value="アソコの大きさ"></v-radio>
          </v-radio-group>
        </div>

        <h2>ランク</h2>
        <div class="white-card container">
          <v-switch v-model="form.rank_aplus" label="A+" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.rank_a" label="A" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.rank_bplus" label="B+" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.rank_b" label="B" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.rank_cplus" label="C+" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.rank_c" label="C" flat hide-details></v-switch>
          <hr />
          <v-switch v-model="form.rank_d" label="D" flat hide-details></v-switch>
        </div>
        <h2>男性のSM度</h2>
        <div class="white-card container">
          <v-radio-group row v-model="form.liked_sm" required>
            <v-radio label="おまかせ" value="0"></v-radio>
            <v-radio label="ドS" value="1"></v-radio>
            <v-radio label="S" value="2"></v-radio>
            <v-radio label="M" value="3"></v-radio>
            <v-radio label="ドM" value="4"></v-radio>
          </v-radio-group>
        </div>

        <div v-show="false">
          <h2>相手のS度</h2>
          <div class="white-card container">
            <v-switch v-model="form.s0" label="１，いちゃいちゃしてるのが一番落ち着く" flat hide-details></v-switch>
            <hr />
            <v-switch v-model="form.s1" label="２，長めに激しく攻めるのが好き" flat hide-details></v-switch>
            <hr />
            <v-switch v-model="form.s2" label="３，首絞め、スパンキング、おもちゃ攻め、拘束などに興奮をする" flat hide-details></v-switch>
            <hr />
            <v-switch v-model="form.s3" label="４，支配的行為で興奮を覚える" flat hide-details></v-switch>
          </div>
          <h2>相手のM度</h2>
          <div class="white-card container">
            <v-switch v-model="form.m0" label="１，攻められるのは苦手" flat hide-details></v-switch>
            <hr />
            <v-switch v-model="form.m1" label="２，自分が攻めたい" flat hide-details></v-switch>
            <hr />
            <v-switch v-model="form.m2" label="３，拘束して攻めて欲しい" flat hide-details></v-switch>
            <hr />
            <v-switch v-model="form.m3" label="４，徹底していじめられたい（射精管理、スパンキング）" flat hide-details></v-switch>
          </div>
        </div>
        <div class="btn-area">
          <button class="btn-comp all-check-btn" @click="checkDefault()">デフォルトに戻す</button>
          <button class="btn-comp login-btn" @click="post()">更新する</button>
        </div>
      </div>
    </div>
  </v-app>
</template>
<style>
  .v-input--selection-controls__input {
    order: 1;
  }
</style>
<style scoped>
  hr {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px dashed #cccccc;
  }

  .alertlink {
    font-size: 1rem;
  }
  h2 {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 20px !important;
  }
  label {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 0 !important;
  }
  input {
    margin-top: 5px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }
  .v-input {
    margin-bottom: 0;
  }
  a {
    color: #4b4b4b;
  }
  a:hover {
    color: #4b4b4b;
  }
  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 360px;
    margin-top: 10px;
    padding: 6px;
    margin: 5px;
    font-size: 1rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }
  .all-check-btn {
    background: #a7a7a7;
    /*background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);*/
  }
  .signin-btn {
    background: rgb(97, 34, 219);
    background: linear-gradient(90deg, rgba(97, 34, 219, 1) 0%, rgba(91, 103, 229, 1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text {
    font-size: 0.8rem;
    padding-top: 20px;
  }
  .get-account-information-text {
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  .v-input {
    margin-left: 15px;
  }
  .v-radio {
    margin-bottom: 10px;
  }
  .v-input__control {
    margin-right: 15px;
    border-bottom: 3px dotted gray;
  }
  .v-label {
    order: -1 !important;
  }
</style>
<style>
  .v-label {
    margin-bottom: 0;
  }
</style>
<script>
  import axios from '../../util/axios'
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'

  export default {
    name: 'form',
    components: {
      Icon,
    },
    data: function () {
      return {
        uid: '',
        client: '',
        access_token: '',
        id: '',
        message: '',
        data_all: '',
        form: {
          body_type0: true,
          body_type1: true,
          body_type2: true,
          body_type3: true,
          body_type4: true,
          sexual_condition: true,
          age_range: true,
          rank_sss: true,
          rank_ss: true,
          rank_s: true,
          rank_aplus: true,
          rank_a: true,
          rank_bplus: true,
          rank_b: true,
          rank_cplus: true,
          rank_c: true,
          rank_d: true,
          rank_e: true,
          liked_sm: 0,
          appear_point: 'おまかせ',
        },
      }
    },
    mounted() {
      this.getAccountData()
      if (this.uid == null) {
        this.$router.push('/login')
      }
    },
    computed: {},
    methods: {
      async getAccountData() {
        this.uid = localStorage.getItem('uid')
        this.client = localStorage.getItem('client')
        this.access_token = localStorage.getItem('access-token')
        if (this.uid) {
          const result = await axios
            .get('/api/recommend_setting', {
              headers: {
                uid: this.uid,
                'access-token': this.access_token,
                client: this.client,
              },
            })
            .catch((e) => {
              e
            })

          if (!result) {
            // エラーの場合ログイン画面へ遷移させる
            this.message = 'エラーが発生しました。'
            return
          }
          this.data_all = result.data
          if (result.data) {
            this.form.body_type0 = result.data.body_type0
            this.form.body_type1 = result.data.body_type1
            this.form.body_type2 = result.data.body_type2
            this.form.body_type3 = result.data.body_type3
            this.form.body_type4 = result.data.body_type4

            this.form.sexual_condition = result.data.sexual_condition
            this.form.age_range = result.data.age_range
            this.form.rank_sss = result.data.rank_sss
            this.form.rank_ss = result.data.rank_ss
            this.form.rank_s = result.data.rank_s
            this.form.rank_aplus = result.data.rank_aplus
            this.form.rank_a = result.data.rank_a
            this.form.rank_bplus = result.data.rank_bplus
            this.form.rank_b = result.data.rank_b
            this.form.rank_cplus = result.data.rank_cplus
            this.form.rank_c = result.data.rank_c
            this.form.rank_d = result.data.rank_d
            this.form.rank_e = result.data.rank_e
            this.form.appear_point = result.data.appear_point
            this.form.liked_sm = String(result.data.liked_sm)
          }
          if (result.data.sex == 2) {
            this.$router.push('/home')
          }
        }
      },
      checkDefault() {
        this.form.body_type0 = true
        this.form.body_type1 = true
        this.form.body_type2 = true
        this.form.body_type3 = true
        this.form.body_type4 = true

        this.form.appear_point = 'おまかせ'
        this.form.liked_sm = '0'

        this.form.sexual_condition = true
        this.form.age_range = true
        this.form.rank_sss = true
        this.form.rank_ss = true
        this.form.rank_s = true
        this.form.rank_aplus = true
        this.form.rank_a = true
        this.form.rank_bplus = true
        this.form.rank_b = true
        this.form.rank_cplus = true
        this.form.rank_c = true
        this.form.rank_d = true
        this.form.rank_e = true
        this.form.s0 = true
        this.form.s1 = true
        this.form.s2 = true
        this.form.s3 = true
        this.form.m0 = true
        this.form.m1 = true
        this.form.m2 = true
        this.form.m3 = true
      },
      async post() {
        const result = await axios({
          method: 'POST',
          url: '/api/recommend_setting',
          headers: {
            'Content-Type': 'application/json',
            uid: this.uid,
            'access-token': this.access_token,
            client: this.client,
          },
          data: {
            recommend_setting: {
              body_type0: this.form.body_type0,
              body_type1: this.form.body_type1,
              body_type2: this.form.body_type2,
              body_type3: this.form.body_type3,
              body_type4: this.form.body_type4,

              sexual_condition: this.form.sexual_condition,
              age_range: this.form.age_range,
              rank_sss: this.form.rank_sss,
              rank_ss: this.form.rank_ss,
              rank_s: this.form.rank_s,
              rank_aplus: this.form.rank_aplus,
              rank_a: this.form.rank_a,
              rank_bplus: this.form.rank_bplus,
              rank_b: this.form.rank_b,
              rank_cplus: this.form.rank_cplus,
              rank_c: this.form.rank_c,
              rank_d: this.form.rank_d,
              rank_e: this.form.rank_e,
              liked_sm: Number(this.form.liked_sm),
              appear_point: this.form.appear_point,
            },
          },
        }).catch((e) => {
          e
        })

        if (!result.data.success) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          this.message = '入力内容を確認してください'
          return
        }
        if (result.data.success) {
          this.getAccountData()
          // this.$router.push("home");
          this.$router.push({ name: 'Home', query: { form_post: true } })
          this.message = '更新しました'
        }
      },
    },
  }
</script>
