<template>
  <div class="login">
    <div class="container">
      <h2>パスワード再設定</h2>
      <div>{{ message }}</div>
      <input type="text" v-model="password" placeholder="設定するパスワードを入力してください" />
      <br />
      <p v-show="password.length < 6">6文字以上で入力してください。</p>
      <input type="text" v-model="password_confirmation" placeholder="パスワードを再度入力してください" />
      <br />
      <button class="login-btn btn-comp" v-on:click="post()">パスワードを設定</button>
      <br />
    </div>
  </div>
</template>

<style scoped>
  input {
    margin-top: 30px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }

  a {
    color: #4b4b4b;
  }
  a:hover {
    color: #4b4b4b;
  }
  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }

  .signin-btn {
    background: rgb(97, 34, 219);
    background: linear-gradient(90deg, rgba(97, 34, 219, 1) 0%, rgba(91, 103, 229, 1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text {
    font-size: 0.8rem;
    padding-top: 20px;
  }
  .get-account-information-text {
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }
</style>

<script>
  import axios from '../../util/axios'
  const qs = require('qs')

  export default {
    name: 'PasswordReset',
    data: function () {
      return {
        email: '',
        password: '',
        password_confirmation: '',
        message: '',
        client: this.$route.query.client,
        uid: this.$route.query.uid,
        access_token: this.$route.query.token,
      }
    },
    mounted() {},
    methods: {
      async post() {
        // const self = this;
        const result = await axios
          .put('/api/auth/password', {
            uid: this.uid,
            credentials: 'include', //add
            'access-token': this.access_token,
            client: this.client,
            password: this.password,
            password_confirmation: this.password_confirmation,
            // redirect_url: "localhost:3001/home",

            paramsSerializer: function (params) {
              return qs.stringify(params, { arrayFormat: 'brackets' })
            },
          })
          .catch((e) => {
            console.error(e)
          })

        if (!result) {
          this.message = 'エラーが発生しました。'
          return
        }
        // if (!result.data) {
        //   this.message = "エラーが発生しました。";
        //   return;
        // }

        if (result) {
          //結果を基にページ遷移
          this.message = 'パスワードの変更が完了しました。'
          this.$router.push('/login')
        }
      },
    },
  }
</script>
