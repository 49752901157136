<template>
  <div class="ReviewForm container" style="text-align: left; margin-bottom: 80px; margin-top: 80px">
    <v-app>
      <v-dialog v-model="dialog" overlay-color="#ffffff" max-width="500">
        <img src="@/assets/reviewform/notification.png" style="width: 100%" @click="dialog = false" />
        <div class="text-center">
          <v-card style="text-align: center" v-if="false">
            <v-card-title class="text-h5">
              <p style="font-size: 1.2rem; line-height: 3rem; margin-bottom: 0">
                <span style="color: #f85b8c; font-weight: 900">
                  男性と性行為があった場合のみ、レビューの入力をお願いします🥺 ⚠️性行為が無かったレビューに関しては削除対象となります
                </span>
              </p>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text scrollable @click="dialog = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>

      <form>
        <div class="form-group">
          <label for="form_dating_date">お会いした日にちを教えてください。</label>
          <div class="white-card">
            <div class="container">
              <input type="date" v-model="review.dating_date" required :max="today" style="width: 100%; min-height: 40px" />
              <span style="color: red" v-show="check_date_restrictions">すでにその日のレビューは投稿済みです。</span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="form_dating_date">DMのやり取りについておしえてください。</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_dm" required>
                <v-radio label="４，とても楽しく盛り上がった、早く会いたくなった" value="4"></v-radio>
                <v-radio label="３，楽しくお話ができた" value="3"></v-radio>
                <v-radio label="２，違和感はなかった" value="2"></v-radio>
                <v-radio label="１，不快だった" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">お会いした印象（雰囲気）をおしえてください。</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_atomosphere" required>
                <v-radio label="４，今までの印象以上のいい人だった（顔も性格も）" value="4"></v-radio>
                <v-radio label="３，想像通りのいい人だった" value="3"></v-radio>
                <v-radio label="２，もともとのイメージと少し乖離があった" value="2"></v-radio>
                <v-radio label="１，全然違う人が来た" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">男性のフォーム（プロフィール）と実際の男性の対応（会話、セックス、事後等）は同じでしたか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_profile" required>
                <v-radio label="４，事前情報より素晴らしい対応をしてくれた。" value="4"></v-radio>
                <v-radio label="３，フォームで書いてある通りの対応だった" value="3"></v-radio>
                <v-radio label="２，フォームに書いてあることと乖離があった。" value="2"></v-radio>
                <v-radio label="１，フォームで書いてあることと全く違うことがあった。" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">舌技は適切でしたか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_tongue" required>
                <v-radio label="４，最高に気持ちよかった。" value="4"></v-radio>
                <v-radio label="３，気持ちよかった" value="3"></v-radio>
                <v-radio label="２，少し違和感（痛みやそこじゃない感）があった" value="2"></v-radio>
                <v-radio label="１，むしろ不快（痛い、等）だった" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">手の技は適切でしたか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_hand" required>
                <v-radio label="４，最高に気持ちよかった。" value="4"></v-radio>
                <v-radio label="３，気持ちよかった" value="3"></v-radio>
                <v-radio label="２，少し違和感（痛みやそこじゃない感）があった" value="2"></v-radio>
                <v-radio label="１，むしろ不快（痛い、等）だった" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">腰使いは適切でしたか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_waist" required>
                <v-radio label="４，最高に気持ちよかった。" value="4"></v-radio>
                <v-radio label="３，気持ちよかった" value="3"></v-radio>
                <v-radio label="２，少し違和感（痛みやそこじゃない感）があった" value="2"></v-radio>
                <v-radio label="１，むしろ不快（痛い、等）だった" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">後戯は適切でしたか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_afterplay" required>
                <v-radio label="４，最高に満たされた" value="4"></v-radio>
                <v-radio label="３，満たされた" value="3"></v-radio>
                <v-radio label="２，少しもの足りない" value="2"></v-radio>
                <v-radio label="１，全然ものたりない" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">前戯時間は適切でしたか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_foreplay_duration" required>
                <v-radio label="４，最高に満たされた" value="4"></v-radio>
                <v-radio label="３，満たされた" value="3"></v-radio>
                <v-radio label="２，少しもの足りない" value="2"></v-radio>
                <v-radio label="１，全然ものたりない" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">挿入時間は適切でしたか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_sex_duration" required>
                <v-radio label="４，最高に満たされた" value="4"></v-radio>
                <v-radio label="３，満たされた" value="3"></v-radio>
                <v-radio label="２，少しもの足りない" value="2"></v-radio>
                <v-radio label="１，全然ものたりない" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">後戯時間は適切でしたか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_afterplay_duration" required>
                <v-radio label="４，最高に満たされた" value="4"></v-radio>
                <v-radio label="３，満たされた" value="3"></v-radio>
                <v-radio label="２，少しもの足りない" value="2"></v-radio>
                <v-radio label="１，全然ものたりない" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">またその男性と会いたいと思いますか？</label>

          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_replay" required>
                <v-radio label="４，何度でも会いたい" value="4"></v-radio>
                <v-radio label="３，また会ってもいいかも" value="3"></v-radio>
                <v-radio label="２，あまり会いたくない" value="2"></v-radio>
                <v-radio label="１，ブロックした（顔も見たくない）" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">感想を具体的にお願いします！</label>

          <div class="white-card">
            <div class="container">
              <v-textarea v-model="review.form_concrete_review" required></v-textarea>
            </div>
          </div>
        </div>
        <div v-show="false">
          <div class="form-group">
            <label for="form_dating_date">もし男性がレビューを求めてきたら匿名＆内容をぼやかして伝えてもいいですか？</label>

            <div class="white-card">
              <div class="container">
                <v-radio-group v-model="review.form_admit_open_review_info" required>
                  <v-radio label="はっきり伝えていい" value="4"></v-radio>
                  <v-radio label="匿名＆ぼかして伝えていい" value="3"></v-radio>
                  <v-radio label="よかったor悪かった、だけ伝えていい" value="2"></v-radio>
                  <v-radio label="伝えないでほしい" value="1"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="form_dating_date">
              みんなのレビューで皆さんの投稿を公開しております。完全匿名でみんなのレビューに公開してもよろしいでしょうか？
            </label>

            <div class="white-card">
              <div class="container">
                <v-radio-group v-model="review.form_admit_publishing" required>
                  <v-radio label="もちろんです！！" value="3"></v-radio>
                  <v-radio label="徹底して特定できそうな情報を消した上でなら大丈夫です！" value="2"></v-radio>
                  <v-radio label="公開しないでほしいです！" value="1"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="form_dating_date">入力していただいたレビューを、ユーザーの皆さんに公開してもよろしいでしょうか？</label>
          <div class="white-card">
            <div class="container">
              <v-radio-group v-model="review.form_admit_full_publishing" required>
                <v-radio label="もちろん大丈夫です！" value="3"></v-radio>
                <v-radio label="公開しないで欲しい" value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
          <p>投稿内容は弊社の外部コンテンツ(Instagram等）に使用させていただく場合がございます</p>
        </div>
        <p>
          {{ message }}
        </p>
      </form>

      <div v-show="isLoading" style="padding-top: 60px">
        <Spinner></Spinner>
      </div>
      <div v-show="!isLoading">
        <button class="btn-comp login-btn" @click="post()" v-show="is_postable">投稿する</button>
        <button class="btn-comp login-btn" disabled v-show="!is_postable">全て入力してください</button>
      </div>
    </v-app>
  </div>
</template>

<style scoped>
  label {
    border-left: 5px solid;
    border-image: linear-gradient(0deg, rgba(96, 33, 220, 1) 0%, rgba(248, 132, 140, 1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4b4b4b;
    text-align: left;
    margin-top: 30px;
  }

  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }
</style>
<script>
  import moment from 'moment'
  import axios from '../../util/axios'
  import Spinner from 'vue-simple-spinner'
  export default {
    name: 'ReviewForm',
    data: function () {
      return {
        message: '',
        uid: '',
        client: '',
        access_token: '',
        all_data: '',
        recommend_id: this.$route.query.id,
        review: [],
        today: moment(Date.now()).format('YYYY-MM-DD'),
        is_additional: false,
        date_restrictions: [],
        isLoading: false,
        dialog: false,
      }
    },
    computed: {
      is_postable() {
        if (
          this.review.dating_date != null &&
          this.review.form_dm != null &&
          this.review.form_atomosphere != null &&
          this.review.form_profile != null &&
          this.review.form_tongue != null &&
          this.review.form_hand != null &&
          this.review.form_waist != null &&
          this.review.form_afterplay != null &&
          this.review.form_foreplay_duration != null &&
          this.review.form_sex_duration != null &&
          this.review.form_afterplay_duration != null &&
          this.review.form_replay != null &&
          this.review.form_concrete_review != null &&
          this.review.form_concrete_review.replace(/\s+/g, '') != '' &&
          this.review.form_admit_full_publishing != null &&
          !this.check_date_restrictions
        ) {
          return true
        } else {
          return false
        }
      },
      check_date_restrictions() {
        if (this.date_restrictions.includes(this.review.dating_date)) {
          return true
        } else {
          return false
        }
      },
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.dialog = true
      if (this.uid == null) {
        this.$router.push('/login')
      }
      this.getAccountData()
    },
    components: {
      Spinner,
    },
    methods: {
      async post() {
        this.message = ''
        this.isLoading = true
        let target = '/api/create_review'
        let target_data = {}
        if (this.is_additional) {
          target = '/api/additional_review'
          target_data = {
            recommend_id: this.recommend_id,
            dating_date: this.review.dating_date,
            form_dm: this.review.form_dm,
            form_atomosphere: this.review.form_atomosphere,
            form_profile: this.review.form_profile,
            form_tongue: this.review.form_tongue,
            form_hand: this.review.form_hand,
            form_waist: this.review.form_waist,
            form_afterplay: this.review.form_afterplay,
            form_foreplay: this.review.form_foreplay,
            form_foreplay_duration: this.review.form_foreplay_duration,
            form_sex_duration: this.review.form_sex_duration,
            form_afterplay_duration: this.review.form_afterplay_duration,
            form_replay: this.review.form_replay,
            form_concrete_review: this.review.form_concrete_review,
            form_admit_open_review_info: this.review.form_admit_open_review_info,
            form_admit_publishing: this.review.form_admit_publishing,
            form_admit_full_publishing: this.review.form_admit_full_publishing,
          }

          const result = await axios
            .post(target, {
              // const result = await axios.post("/api/create_review", {
              headers: {
                uid: this.uid,
                'access-token': this.access_token,
                client: this.client,
              },
              additional_review: target_data,
            })
            .catch((e) => {
              e
            })
          this.isLoading = false
          this.message = '投稿しました。'

          this.review = result.data.review
          this.$router.push({ name: 'Home', query: { create_review: true } })
        } else {
          target_data = {
            id: this.recommend_id,
            dating_date: this.review.dating_date,
            form_dm: this.review.form_dm,
            form_atomosphere: this.review.form_atomosphere,
            form_profile: this.review.form_profile,
            form_tongue: this.review.form_tongue,
            form_hand: this.review.form_hand,
            form_waist: this.review.form_waist,
            form_afterplay: this.review.form_afterplay,
            form_foreplay: this.review.form_foreplay,
            form_foreplay_duration: this.review.form_foreplay_duration,
            form_sex_duration: this.review.form_sex_duration,
            form_afterplay_duration: this.review.form_afterplay_duration,
            form_replay: this.review.form_replay,
            form_concrete_review: this.review.form_concrete_review,
            form_admit_open_review_info: this.review.form_admit_open_review_info,
            form_admit_publishing: this.review.form_admit_publishing,
            form_admit_full_publishing: this.review.form_admit_full_publishing,
            review: 1,
          }

          const result = await axios
            .post(target, {
              // const result = await axios.post("/api/create_review", {
              headers: {
                uid: this.uid,
                'access-token': this.access_token,
                client: this.client,
              },
              recommend: target_data,
            })
            .catch((e) => {
              // console.error(e);
              e
            })
          this.isLoading = false
          this.message = '投稿しました。'

          this.review = result.data.review
          this.$router.push({ name: 'Home', query: { create_review: true } })
        }
      },

      async getAccountData() {
        this.uid = localStorage.getItem('uid')
        this.client = localStorage.getItem('client')
        this.access_token = localStorage.getItem('access-token')

        if (this.uid) {
          const result = await axios
            .get('/api/review', {
              headers: {
                uid: this.uid,
                'access-token': this.access_token,
                client: this.client,
              },
              params: {
                recommend_id: this.recommend_id,
              },
            })
            .catch((e) => {
              e
            })

          if (!result) {
            // エラーの場合ログイン画面へ遷移させる
            this.message = 'エラーが発生しました。'
            this.$router.push('/login')
            return
          }
          if (!result.data.review) {
            this.$router.push('/login')
          }

          if (this.review.dating_date == '') {
            this.review.dating_date = null
          }
          if (result.data.review.form_dm != null) {
            this.is_additional = true
          } else {
            // this.review=result.data.review
          }
          this.date_restrictions = result.data.review.date_restrictions
        }
        window.scrollTo({ top: 0 })
      },
    },
  }
</script>
