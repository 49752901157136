<template>
  <div class="login">
    <div class="container" style="margin-top: 50px">
      <transition name="bounce">
        <div v-show="this.show_flash_message" class="flash">
          <p class="flash-text">
            <Icon name="info-circle" scale="1" style="color: #775ddf; padding-right: 5px" />
            <span v-html="message" style="text-align: left"></span>
          </p>
        </div>
      </transition>
      <input type="text" v-model="email" placeholder="メールアドレスを入力してください" />
      <br />
      <input type="password" v-model="password" placeholder="パスワードを入力してください" />
      <br />
      <button class="login-btn btn-comp" v-on:click="login()">ログイン</button>
      <br />
      <p></p>
      <router-link to="/forget_password" class="forgot-information-text"><p>登録アドレス・パスワードを忘れてしまったら</p></router-link>
      <p class="get-account-information-text">アカウントをお持ちでない場合</p>
      <router-link to="/signup">
        <button class="signin-btn btn-comp">会員登録</button>
      </router-link>
      <p class="get-account-information-text">パスワードを忘れてしまった場合</p>
      <router-link to="/forget_password">
        <button class="signin-btn btn-comp">パスワード再設定</button>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
  input {
    margin-top: 30px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }

  a {
    color: #4b4b4b;
  }
  a:hover {
    color: #4b4b4b;
  }
  .btn-comp {
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .login-btn {
    background: rgb(248, 70, 129);
    background: linear-gradient(90deg, rgba(248, 70, 129, 1) 0%, rgba(255, 150, 145, 1) 100%);
  }

  .signin-btn {
    background: rgb(97, 34, 219);
    background: linear-gradient(90deg, rgba(97, 34, 219, 1) 0%, rgba(91, 103, 229, 1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text {
    font-size: 0.8rem;
    padding-top: 20px;
  }
  .get-account-information-text {
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }
</style>

<script>
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'
  import axios from '../../util/axios'
  // axios.defaults.baseURL = 'http://54.168.164.59/';
  axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'

  axios.defaults.headers.post['Access-Control-Allow-Origin'] = process.env.VUE_APP_BASE_API_URL

  const qs = require('qs')

  export default {
    name: 'logIn',
    components: {
      Icon,
    },
    data: function () {
      return {
        uid: '',
        client: '',
        access_token: '',
        email: '',
        password: '',
        message: '',
        show_flash_message: false,
      }
    },

    head: {
      title() {
        return {
          inner: '性癖マッチン',
          separator: '|',
          complement: '',
        }
      },
      meta: [{ name: '性癖マッチン', content: '性癖マッチン', id: 'desc' }],
    },

    mounted() {
      this.getAccountData()
      this.message = localStorage.getItem('message')
      // this.hasMessage();
      this.mailMessage()
    },

    methods: {
      mailMessage() {
        if (this.$route.query.send_mail) {
          this.message = '入力されたメールアドレス宛に確認用メールを送信しました。'
          setTimeout(() => {
            this.show_flash_message = true
          }, 1000)
          setTimeout(() => {
            this.show_flash_message = false
          }, 10000)
        }
      },
      hasMessage() {
        if (this.$route.query.send_mail || this.message != null) {
          if (this.message != null) {
            this.message = '18歳未満は登録不可です。<br>18歳以上になったらお問い合わせください。'
          } else {
            this.message = '入力されたメールアドレス宛に確認用メールを送信しました。'
          }
          setTimeout(() => {
            this.show_flash_message = true
          }, 1000)
          setTimeout(() => {
            this.show_flash_message = false
          }, 10000)
        }
      },
      async getAccountData() {
        this.uid = localStorage.getItem('uid')
        this.client = localStorage.getItem('client')
        this.access_token = localStorage.getItem('access-token')
        if (this.uid) {
          // const result = await axios.get("https://matching.itonami.design/api/account", {
          const result = await axios
            .get('/api/account', {
              headers: {
                uid: this.uid,
                'access-token': this.access_token,
                client: this.client,
              },
            })
            .catch((e) => {
              e
            })

          if (!result) {
            // エラーの場合ログイン画面へ遷移させる
            localStorage.removeItem('uid')
            localStorage.removeItem('client')
            localStorage.removeItem('access-token')
            // this.redirectLogin();
            return
          }
          if (!result.data.email) {
            // エラーの場合ログイン画面へ遷移させる
            localStorage.removeItem('uid')
            localStorage.removeItem('client')
            localStorage.removeItem('access-token')
            // this.redirectLogin();
            return
          }
          if (result.data.email) {
            this.$router.push('/home')
          }
        }
      },
      async login() {
        // const self = this;

        const result = await axios
          // .post("https://matching.itonami.design/api/auth/sign_in", {
          .post('/api/auth/sign_in', {
            credentials: 'include',
            email: this.email,
            password: this.password,

            paramsSerializer: function (params) {
              return qs.stringify(params, { arrayFormat: 'brackets' })
            },
          })
          .catch((e) => {
            e
          })

        if (!result) {
          this.message = 'メールアドレスとパスワードを確認してください。'
          setTimeout(() => {
            this.show_flash_message = true
          }, 1000)
          setTimeout(() => {
            this.show_flash_message = false
          }, 5000)
          return
        }
        if (!result.data) {
          this.message = 'メールアドレスとパスワードを確認してください。'
          setTimeout(() => {
            this.show_flash_message = true
          }, 1000)
          setTimeout(() => {
            this.show_flash_message = false
          }, 5000)
          return
        }

        if (result.data) {
          //結果を基にページ遷移
          localStorage.setItem('access-token', result.headers['access-token'])
          localStorage.setItem('client', result.headers['client'])
          localStorage.setItem('uid', result.headers['uid'])
          localStorage.setItem('token-type', result.headers['token-type'])
          localStorage.setItem('expiry', result.headers['expiry'])

          this.$router.push('/home')
        }
      },
    },
  }
</script>
