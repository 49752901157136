import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    counter: 0,
    unread_messages_size: 0,
  },
  mutations: {
    incrementMutateCounter(state, payload) {
      // stateへ
      state.counter += payload.inc
    },
    change_unread_messages_size(state, payload) {
      state.unread_messages_size = payload
    },
  },
  actions: {
    incActionCounter(store, payload) {
      // Mutationsへ
      store.commit('incrementMutateCounter', payload)
    },
  },
})
