<template>
  <div class="Paymentinformation container content-wrapper" style="padding-top: 10px">
    <div class="white-card" v-if="payment_conf != 1">
      <h1 style="margin-bottom: 0; font-size: 1.23rem; color: #4b4b4b">決済登録</h1>
    </div>
    <h2>決済状況</h2>
    <div v-show="payment_conf != 1">
      <div class="white-card">
        <div class="icon-wrapper">
          <div class="icon-area">
            <Icon name="credit-card" class="card-icon" scale="20" style="max-width: 80vw" />
            <Icon name="times-circle" class="times-circle" scale="7" style="color: #da0047" />
          </div>
        </div>
        <p>お支払いの確認が取れていません</p>
        <p>メールアドレス登録不備がある場合、お支払いの確認ができません</p>
      </div>
    </div>
    <div v-show="payment_conf == 1">
      <div class="row">
        <div class="col-md-6">
          <div class="white-card">
            <div class="icon-wrapper">
              <div class="icon-area">
                <Icon name="credit-card" class="card-icon" scale="17" style="" />
                <Icon name="check-circle" class="times-circle" scale="7" style="color: #8fc02c" />
              </div>
            </div>
            <p>お支払い確認済み</p>
          </div>
        </div>
        <div class="col-md-6">
          <h2>決済確認情報</h2>
          <div class="white-card container" style="text-align: left">
            <p><b>お客様ID(決済用)</b></p>
            <p>{{ this.payment_id }}</p>
            <p><b>お客様パスワード(決済用)</b></p>
            <p>{{ this.payment_pass }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="payment_conf == 1">
      <h2>プラン情報</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="white-card container" style="text-align: left">
            {{ course_name }}
            <span v-if="Date.now() < Date.parse(next_payment_date)">:{{ next_payment_date }}</span>
            <p style="padding-top: 3px">
              明細にはitonamiと表記されます。ご利用のカード会社によりVISAMasterCardはmediecp、JCBはEV-PA 03-6825-1606と表記される場合があります。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-show="payment_conf != 1">
      <h2>お支払いに関して</h2>
      <div class="row">
        <div class="col-md-12">
          <div class="white-card" style="padding-bottom: 20px">
            <p style="padding: 5px">
              お支払いが
              <span style="color: #d90045">完了していない場合</span>
              は
              <br />
              決済登録ページから
              <br />
              決済の登録をお願いします。
            </p>
            <div v-if="false">
              <a
                :href="'https://mc-credit.com.sg/service/credit/input.html?SiteId=32307803&SitePass=JRjF6Nnk&Amount=2000&Amount2=2000&mail=' + email"
              >
                <button class="payment-btn">
                  決済登録をする
                  <Icon name="external-link-alt" class="card-icon" scale="1.2" style="" />
                </button>
              </a>
            </div>
            <div v-else>
              <router-link to="payment_select">
                <button class="payment-btn">
                  プランを選択する
                  <Icon name="external-link-alt" class="card-icon" scale="1.2" style="" />
                </button>
              </router-link>
              <div>
                <v-btn @click="$router.push('/hona')" style="margin-top: 30px">運営とのチャットはこちら</v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="false">
          <div class="white-card">
            <p style="padding: 5px">
              <span style="color: #d90045">決済を再開されたい場合</span>
              は
              <br />
              再開の申請登録を
              <br />
              お願いします。
              <br />
            </p>
            <a href="https://mc-credit.com.sg/service/continue/again.htm?SiteId=32307803&SitePass=JRjF6Nnk">
              <button class="payment-btn">
                再開申請をする
                <Icon name="external-link-alt" class="card-icon" scale="1.2" style="" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-show="payment_conf == 1">
      <h2>決済退会</h2>
      <div class="row">
        <div class="col-md-6">
          <div class="white-card container" style="text-align: left">
            <p>※決済を停止されますと、サービスはご利用いただけなくなります</p>
            <router-link to="payment_cancellation">決済退会ページ</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .card-icon {
    background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .icon-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    width: 100%;
  }
  .icon-area {
    position: relative;
    max-width: 600px;
  }
  .times-circle {
    position: absolute;
    bottom: 10px;
    right: 5px;
  }

  .payment-btn {
    width: 80%;
    border-radius: 3px;
    border: 1px solid #d3d3d3;
    padding: 20px;
    font-size: 1.3rem;
  }

  a {
    text-decoration: none;
  }
</style>

<script>
  import axios from '../../util/axios'
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'

  export default {
    name: 'Paymentinformation',

    components: {
      Icon,
    },
    data() {
      return {
        isLoading: true,
        completedSteps: 0,
        uid: '',
        client: '',
        access_token: '',
        email: '',
        twitterid: '',
        info: '',
        info_ts: '',
        payment_conf: '',
        age_conf: '',
        data_all: '',
        rank: '-',
        sex: '',
        next_payment_date: '',
        payment_pass: '',
        payment_id: '',
        course_name: '',
        matching_count: 0,
        recommend_count: 0,
      }
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData()
      if (this.uid === null) {
        this.$router.push('/login')
      }
    },

    methods: {
      async getAccountData() {
        const result = await axios
          .get('/api/payment_information', {
            headers: {
              uid: this.uid,
              'access-token': this.access_token,
              client: this.client,
            },
          })
          .catch((e) => {
            console.error(e)
          })

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return
        }
        this.payment_id = result.data.payment_id
        this.payment_pass = result.data.payment_pass
        this.payment_conf = result.data.payment_conf
        this.payment_kind = result.data.payment_kind
        this.last_payment_date = result.data.last_payment_date
        this.next_payment_date = result.data.next_payment_date
        this.course_name = result.data.course_name
        this.email = result.data.email

        if (this.matching_count == null) {
          this.matching_count = 0
        }
        if (this.recommend_count == null) {
          this.recommend_count = 0
        }
        this.isLoading = false
        setTimeout(this.setCompletedSteps, 1000)
      },
    },
  }
</script>
