import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css' // add
import 'bootstrap-vue/dist/bootstrap-vue.css' // add
import vuetify from './plugins/vuetify'
import Vuex from 'vuex'
import VueScrollTo from 'vue-scrollto'
import VueClipboard from 'vue-clipboard2'
import VuePaginate from 'vue-paginate'
import store from './store'

Vue.use(VuePaginate)
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueScrollTo)

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
import axios from '../util/axios'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL

new Vue({
  router,
  vuetify,
  VuePaginate,
  store,
  render: (h) => h(App),
}).$mount('#app')
